@import '../settings/config';
@import '../utilities/_vary.scss';
@import '../utilities/_mixins.scss';
@import '../utilities/_placeholders.scss';

.performance {
    &__assessment--create,
    &__monitoringType,
    &__indicators {
        padding: $padding-element-side;

        @include fancySection;

        > .row {
            background-color: $color-white;
            padding: $padding-element;

            @include vary($create: parent) {
                border: 1px solid vary-get('color-border');
            }

            > .column {
                margin-top: $margin-element;
            }
        }

        &--editing {
            > .row {
                @include hoverColor;
            }
        }
    }

    &__indicator {
        background-color: $color-white;
        padding: $padding-element;

        @include firstAndLastElementMargins;

        @include vary($create: parent) {
            border: 1px solid vary-get('color-border');
        }

        > .row {
            margin-top: $margin-element;
        }

        &--editing {
            @include hoverColor;
        }
    }

    &__action {
        padding: $padding-element-side-left;
        border: 1px solid $light-gray;

        @include firstAndLastElementMargins;

        &--editing {
            @include hoverColor;
        }

        &__details {
            padding-right: $padding-element-x;

            > .row {
                margin-top: $margin-element;
            }
        }
    }


    &__response {
        padding: $padding-element;
        border-left: 1px solid $system-color-shadow;


        &--editing {
            margin-right: $padding-element-x;
            padding: $padding-element;

            @include firstAndLastElementMargins;
            @include hoverColor;
        }

        h4 {
            margin-top: 0;
        }

        > .row {
            margin-top: $margin-element;
        }

        &__attachments {
            .row {
                display: inline-block;
                margin-right: $margin-element;
                margin-top: $margin-element;
            }
        }

        &__upload {
            .row {
                margin-top: $margin-element;
            }
        }
    }
}
