.helpText {
    z-index: 100;
    display: inline-block;
    text-indent: 0;

    .button--helpText {
        cursor: help !important;
        position: relative;
        z-index: 101;

        &:focus + .helpText__message,
        &:active + .helpText__message,
        &:hover + .helpText__message {
            //show
            border-width: 1px;
            max-height: 4em;
            opacity: 1;
            overflow: visible;
            display: block;
        }

        &:before {
            background-color: white;
            border-radius: 50%;
            color: black;
            font-size: 16px;
            line-height: .99; //white outline if 1
        }
    }

    .helpText__message {
        background-color: $color-white;
        border-style: solid;
        border-color: $color-black;
        color: $color-black;
        font-weight: normal;
        position: absolute;
        left: 2.3rem;
        top: 50%;
        font-size: 0.9rem;
        transform: translate(0, -50%);
        transition: opacity .15s;
        padding: .5rem .5rem;
        text-align: center;
        //hide
        border-width: 0;
        max-height: 0;
        opacity: 0;
        overflow: hidden;
        display: none;
        white-space: nowrap;

        &:before {
            border-width: 5px;
            border-style: solid;
            border-color: transparent $color-black transparent transparent;
            content: "";
            position: absolute;
            top: 50%;
            left: 0;
            right: 100%;
            margin-top: -5px;
            margin-left: -2.3rem;
        }

        @include borderShadow($color-black);
    }
}
