﻿@import '../settings/config';
@import '../utilities/_vary.scss';
@import '../utilities/_mixins.scss';
@import '../utilities/_placeholders.scss';



.strategyPlanTeam {
    border: 1px solid $system-color-border;
    padding: 0px $padding-element-x;
    page-break-inside: avoid;

    &--editing {
        @include hoverColor;

        > .row {
            margin-top: $margin-element;
            margin-right: $margin-element;

            > .columns {
                padding-right: $padding-element-x;

                @include breakpoint(medium down) {
                    padding-top: $padding-element-small-top;
                }
            }
        }
    }
}

.strategy {

    &:not(:last-child) {
        margin-top: $margin-element * 2;
    }

    &:not(:last-child) {
        margin-bottom: $margin-element * 2;
    }

    @media print {
        .strategy__row {
            background: none !important;
            padding-left: 0 !important;
            padding-right: 0 !important;

            > label {
                display: none !important;
            }
        }
    }

    @media screen {
        @include borderShadow;
        @include roundedCorners;

        @include vary($create: parent) {
            background-color: vary-get('color-row');
            border-bottom: 4px solid vary-get('color-border');
        }
    }

    h2 {
        margin: $margin-element 0;
        padding: $padding-element;
    }

    .strategy__row:not(:empty) {
        padding: .1px $padding-element-x $padding-element-y; //needed for top margin for some reason
        &:nth-of-type(even) {
            @include vary($create: parent) {
                background-color: vary-get('color-row');
            }
        }

        > label {
            display: block;
            margin-top: $margin-element;
            text-align: center;
        }
    }

    .strategy__action,
    .strategy__goal,
    .strategy__indicator {
        background-color: $color-white;
        page-break-inside: avoid;

        @include vary($create: parent) {
            border: 1px solid vary-get('color-border');
        }

        h2,
        h3,
        h4 {
            margin-bottom: 0;
        }
    }

    .strategy__goal {
        @include firstAndLastElementMargins;
        padding: 0 $padding-element-x;
        page-break-before: auto;
        page-break-after: avoid;

        &--editing {
            @include hoverColor;
        }
    }

    .strategy__action,
    .strategy__indicator {
        margin-top: $margin-element;

        &--editing {

            &:before {
                display: none;
            }

            > header {
                @include vary($create: parent) {
                    border: 1px solid vary-get('color-border');
                }

                @include hoverColor;
            }
        }

        > header {
            background-color: $color-white;

            @include firstAndLastElementMargins;

            &:not(:last-child) {
                @include vary($create: parent) {
                    border-bottom: 1px solid vary-get('color-border');
                }
            }
        }

        .strategy__baseline,
        .tileContainer,
        header {
            padding: 0 $padding-element-x;
        }

        .strategy__baseline:last-child {
            margin-bottom: $margin-element;
        }

        .strategy__progress .tileContainer,
        .strategy__progressActivity,
        .strategy__progress header {
            padding: 0 (2 * $padding-element-x);
        }

        .strategy__progressActivity {
            p {
                @include vary($create: parent) {
                    border-left: 2px solid vary-get('color-border');
                }
            }
        }

        .strategy__progressActivity,
        .strategy__metrics {
            p {
                padding-left: $padding-element-x;
            }

            &--editing {
                background-color: $color-white;
                @include firstAndLastElementMargins;

                @include vary($create: parent) {
                    border: 1px solid vary-get('color-border');
                }

                @include hoverColor;
            }
        }

        .strategy__metrics {
            &--editing {
                margin-top: 0;
                padding-left: $padding-element-x;

                textarea {
                    overflow: hidden;
                }
                //baseline
                > .row {
                    margin-top: $margin-element;

                    > .columns {
                        padding-right: $padding-element-x;

                        @include breakpoint(medium down) {
                            padding-top: $padding-element-small-top;
                        }
                    }
                }
            }
        }

        .strategy__subaction {

            &:not(.strategy__subaction--editing) {
                @include firstAndLastElementMargins;
            }

            &--editing {
                margin-top: 0;

                > header {
                    background-color: $color-white;

                    @include firstAndLastElementMargins;

                    @include vary($create: parent) {
                        border: 1px solid vary-get('color-border');
                    }

                    @include hoverColor;
                }
            }
        }

        p {
            label {
                padding: 0;
            }
        }
    }
}
