﻿@import '../utilities/_mixins.scss';

.buttonBar {
    background-color: black(.05, $color-white);
    display: block;
    margin: $margin-element 0;
    padding: $padding-element-y/2;
    text-align: center;

    .buttonBar__buttonContainer,
    .button--mock,
    .button {
        display: inline-block;
        margin: $margin-element/2;
    }

    &.buttonBar-- {
        &column {
            margin-top: 0;
        }

        &top {
            border: 1px solid $system-color-border;
            margin-top: 0;
        }

        &fullWidth {
            display: flex;

            .buttonBar__buttonContainer {
                flex-basis: 33.33%;
            }
        }

        &fixed {
            @include stickyShadow;
            @include roundedCorners;

            @include breakpoint(medium) {
                position: fixed;
                top: 0;
                right: 0;
                z-index: 500;
            }

            @include breakpoint(medium only) {
                position: sticky;
            }
        }

        &sticky,
        &stickyBottom {
            @include roundedCorners;
            @include stickable(true);

            margin-left: 2px;
            margin-right: 2px;
            z-index: 300 !important;
        }

        &stickyBottom {
            @include stickable(false);
        }

        &sticky.buttonBar--smallStickyHeight {
            @include stickable(true, 400px);
        }

        &stickyBottom.buttonBar--smallStickyHeight {
            @include stickable(false, 400px);
        }

        &table,
        &bottom {
            margin-bottom: 0;
            margin-top: $margin-element/2;
            padding: 0;
        }

        &table {
            @include borderShadow;
            @include roundedCorners;

            background-color: black(.05);
        }

        &submission-module {
            @include borderShadow;
            @include firstAndLastElementMargins;

            text-align: left;
            padding: 0 $padding-element-x;
            margin-top: $margin-element * 2;

            .button--container {
                text-align: center;
            }
        }
    }

    @media print {
        display: none !important;
    }
}

.filterBar {
    .buttonBar {
        background-color: unset;
        margin: auto;
        padding: $padding-element-y/2 $padding-element-y/2 0;
        width: 100%;
    }
}

.table-grid {
    .buttonBar {
        background-color: unset;
        margin: auto;
        padding: 0;
        white-space: nowrap;
    }
}
