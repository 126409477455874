@import '../settings/config';
@import '../utilities/_placeholders.scss';
@import '../utilities/_functions.scss';

.theme-plan {
    @media print {
        .content {
            & * {
                color: #000 !important;
                border-color: #000 !important;
            }

            > header {
                text-align: center;
            }
        }

        .strategyPlan {
            > header {
                text-align: center;
            }

            .strategy > header {
                display: none;
            }

            &:not(:last-child) {
                page-break-after: always;
            }
        }

        .tileContainer__tile {
            min-height: 68px !important;
        }

        .tileContainer__tile__footer {
            background: none !important;
        }

        .strategy__goal,
        .strategy__goal > header {
            background-color: havelock(.2, white) !important;
        }

        .strategy__indicator,
        .strategy__indicator > header {
            background-color: havelock(.1, white) !important;

            .tileContainer:first-child .tileContainer__tile {
                background-color: cardinal(.1, white) !important;
            }
        }
    }



    .tileContainer__tile--new {

        &.tileContainer__tile--medium {
            .tileContainer__tile__content {
                &:before {
                    vertical-align: 20%;
                }
            }
        }

        &.tileContainer__tile--large {
            .tileContainer__tile__content {
                &:before {
                    vertical-align: 40%;
                }
            }
        }
    }

    ul {
        margin-top: 0;
    }

    li:not(:last-child):after {
        margin-top: 0;
    }
}
