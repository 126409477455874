@import 'inputTheme';

.task-icon-list, .task-icon-row {
  img {
    margin-right: 0.5rem;
  }
}

.task-icon-list {
  list-style: none;
  @include indent-css(0.4rem, -1.7rem);
}

.task-icon-row div {
  @include indent-css(1.4rem, -1.7rem);
}

.navigation-buttons {
  button.button {
    margin-left: $margin-element * 1.5;
    margin-right: $margin-element * 1.5;
  }
}

.summary-tables {
  tr {
    vertical-align: top;

    td ul, td ol {
      margin: 0;
      padding-left: $padding-element-x;
    }

    td ul {
      @extend .single-space
    }
  }
}