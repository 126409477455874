@import '../settings/_config';
@import '../utilities/_mixins.scss';

.loader {
	position: relative;
	z-index: -1000;

	span {
		@extend .is-visuallyhidden;
	}

	&:before {
		background: url("../images/icon-loading.png") center center no-repeat;
		background-size: cover;
		border-radius: 50%;
		content: '';
		display: block;
		// to perfectly center
		margin-left: -50px;
		margin-top: -50px;
		//
		height: 100px;
		width: 100px;
		overflow: hidden;
		//
		position: fixed;
		top: 50%;
		left: 50%;
	}

    &--isLoading {
		z-index: 1000;

		&:before {
			@include spin(7s);
		}
	}
}



