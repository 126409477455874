﻿@function defaultColor($opacity, $bgcolor: "") {
    @return havelock($opacity, $bgcolor);
}

@function opacify($fore, $back) {
    $ored: ((1 - alpha($fore)) * red($back) ) + (alpha($fore) * red($fore));
    $ogreen: ((1 - alpha($fore)) * green($back) ) + (alpha($fore) * green($fore));
    $oblue: ((1 - alpha($fore)) * blue($back) ) + (alpha($fore) * blue($fore));
    @return rgb($ored, $ogreen, $oblue);
}

/* Grayscale */
@function black($opacity, $bgcolor: "") {
    $color: rgba(0, 0, 0, $opacity);

    @if ($bgcolor == "") {
        @return $color;
    }

    @return opacify($color, $bgcolor);
}

@function white($opacity, $bgcolor: "") {
    $color: rgba(255, 255, 255, $opacity);

    @if ($bgcolor == "") {
        @return $color;
    }

    @return opacify($color, $bgcolor);
}

/* Dark Blue */
@function darkblue($opacity, $bgcolor: "") {
    $color: rgba(36, 102, 171, $opacity);

    @if ($bgcolor == "") {
        @return $color;
    }

    @return opacify($color, $bgcolor);
}

/* Orange */
@function orange($opacity, $bgcolor: "") {
    $color: rgba(173, 98, 0, $opacity);

    @if ($bgcolor == "") {
        @return $color;
    }

    @return opacify($color, $bgcolor);
}

/* Blue = Default */
@function havelock($opacity, $bgcolor: "") {
    $color: rgba(31, 118, 191, $opacity);

    @if ($bgcolor == "") {
        @return $color;
    }

    @return opacify($color, $bgcolor);
}

/* Teal */
@function teal($opacity, $bgcolor: "") {

    $color: rgba(0, 130, 120, $opacity);

    @if ($bgcolor == "") {
        @return $color;
    }

    @return opacify($color, $bgcolor);
}

/* Green */
@function atlantis($opacity, $bgcolor: "") {
    $color: rgba(54, 132, 13, $opacity);

    @if ($bgcolor == "") {
        @return $color;
    }

    @return opacify($color, $bgcolor);
}

/* Lime */
@function lime($opacity, $bgcolor: "") {
    $color: rgba(31, 118, 191, $opacity);

    @if ($bgcolor == "") {
        @return $color;
    }

    @return opacify($color, $bgcolor);
}

/* Gold */
@function gold($opacity, $bgcolor: "") {
    $color: rgba(238, 210, 0, $opacity);

    @if ($bgcolor == "") {
        @return $color;
    }

    @return opacify($color, $bgcolor);
}

/* Red */
@function cardinal($opacity, $bgcolor: "") {
    $color: rgba(196, 44, 31, $opacity);

    @if ($bgcolor == "") {
        @return $color;
    }

    @return opacify($color, $bgcolor);
}

/* Purple */
@function purple($opacity, $bgcolor: "") {
    $color: rgba(162, 0, 162, $opacity);

    @if ($bgcolor == "") {
        @return $color;
    }

    @return opacify($color, $bgcolor);
}
