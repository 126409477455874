
.theme-gms {
    .input-center {
        .columns:not(.text-left) {
            input[type="text"] {
                text-align: center;
            }
        }
    }

    .idea {

        legend,
        label {
            font-weight: normal;

            + p {
                margin-top: 0;
            }
        }

        p:first-child {
            margin-top: 1em;
        }

        p:last-child {
            margin-bottom: 1em;
        }

        ol[type="a"] {
            ol {
                list-style-type: decimal;

                ol {
                    list-style-type: lower-roman;

                    ol {
                        list-style-type: upper-alpha;
                    }
                }
            }
        }
    }

    .applicationHistoryBar {
        border: 1px solid $system-color-border;
        margin-bottom: $global-margin;

        @include firstAndLastElementMargins;

        .columns {
            @include grid-column-gutter($padding-element-x, $padding-element-x);
        }

        @media print {
            display: none;
        }

        &__buttonColumn {
            display: flex;
            button {
                align-self: flex-end;
            }
        }

        &--noBorder {
            border: none;
        }
    }

    .doe100DownloadContainer {
        background-color: $color-gray-light;
        padding: $global-padding;
        margin: -0.8rem;
        margin-bottom: $global-margin;

        .buttonBar {
            background-color: inherit;
        }
    }

    .caseNotes {
        ol, ul {
            margin: 0;

            li {
                line-height: normal;

                &:after {
                    content: '';
                    margin-top: 0;
                }
            }
        }

        td ol {
            padding-left: $padding-element-x;
        }

        td.response-no-wrap {
            white-space: nowrap;
        }

        span.clarifying-text {
            font-size: 0.8em;
            font-style: italic;
        }

        .tfoot td {
            font-weight: bold;
        }
    }

    .budgetEditRow {
        > .columns {
            padding-left: $padding-element-x;
            padding-right: $padding-element-x;

            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                padding-right: 0;
            }
        }

        &__otherText {
            margin-top: $margin-element;
        }

        &__budgetLineItemTitle {
            > div {
                margin-top: $margin-element;
                margin-bottom: $margin-element;
                font-size: 1.1em;
            }

            &--deleted {
                color: $color-delete;
            }

            &--addedNew {
                color: $color-add-item-dark;
            }

            &--editCurrent {
                color: $system-color-foreground;
            }
        }
    }

    .budgetButtonContainer {
        margin-top: $margin-element;
    }

    .reportList {
        list-style: none;
        margin: $margin-element 0;
        padding: 0;
    }
}
