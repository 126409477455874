@import 'inputTheme';
@import 'taskTheme';

.theme-print {
  .table-print {
    &.dr-summary-due-now-table,
    &.dr-summary-upcoming-table {
      display: table;
    }
  }
}

.theme-dispute {
  .reminders-table {
    th.remind-on {
      white-space: nowrap;
    }
  }

  .intake-versions-table, .intake-requirements-table {
    td.actions, td.typeOutput {
      white-space: nowrap;
    }
  }

  .state-complaint-tasks {
    margin-top: $margin-element * 2;
    margin-bottom: $margin-element * 2;

    h2 {
      font-size: 1rem;
    }
  }

  .case-list-table, .dr-summary-due-now-table, .dr-summary-upcoming-table {
    @extend .summary-tables
  }

  .caseload-table {
    tr {
      td, th {
        text-align: center;
      }
    }
  }

  .drNavigation {
    @extend .navigation-buttons
  }

  ul.single-space {
    @extend .single-space
  }
}