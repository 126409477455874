﻿@import '../settings/_config.scss';
@import '../settings/_settings.scss';
@import '../utilities/_vary.scss';
@import '../utilities/_functions.scss';
@import '../utilities/_mixins.scss';

%focusable {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
}

%hangingIndent {
    margin-left: 2rem;
    text-indent: -0.8rem;
}

%arrowBase {
    border: 10px solid transparent;
    content: "";
    margin-top: .1em;
}

%buttonBase {
    background: $color-white;
    border-color: transparent;
    border-radius: $border-radius;
    border-style: solid;
    border-width: 1px;
    display: inline-block;
    min-height: rem-calc(42px);
    min-width: rem-calc(42px);
    padding: $padding-button;
    //vertical-align: bottom; ???
    & + .button {
        margin-left: 1em;
    }

    &:disabled {
        @extend %disabled;
    }
}

%buttonLight {

    @include vary($create: parent) {
        background: $color-white;
        border: 1px solid vary-get('color-border');
        color: vary-get('color-foreground');
    }

    @include clickable();

    &.is-active {
        background: $system-color-foreground;
        color: $color-white;

        @include vary($create: parent) {
            background: vary-get('color-foreground');
        }
    }
}

%buttonSystem {
    background: $system-color-background;
    border: 1px solid $system-color-border;
    color: $color-white;

    &:not(:disabled) {
        @include clickable($system-color-shadow);
    }

    &.is-active {
        background: $system-color-foreground;
        color: $color-white;
    }
}

%buttonRoundIcon {
    border-color: $system-color-border;
    color: $system-color-foreground;
}

%buttonSystemLight {
    background: $color-white;
    border: 1px solid $system-color-border;
    color: $system-color-foreground;

    @include clickable($system-color-shadow);

    &.is-active {
        background: $system-color-foreground;
        color: $color-white;
    }

    &:disabled,
    &.is-disabled {
        @extend %disabled;
    }
}

%buttonSmall {
    border: 1.25px solid currentColor;
    border-radius: 50%;
    display: inline-block;
    font-size: .75rem;
    padding: .125em;
}

%responsiveTable {

    @include breakpoint(medium down) {
        &,
        thead,
        tbody,
        tfoot,
        tr,
        td,
        th {
            display: block;
            width: 100%;
        }

        thead,
        tbody,
        tfoot {
            td,
            th {
                border: none !important;
            }
        }
    }
}

%disabled {
    border-color: $color-disabled-dark;
    color: $color-disabled-dark;
    opacity: $opacity-disabled;
    @extend %notHoverable;
    @extend %notClickable;
}

%notHoverable {
    outline: none;
    transform: none;
}

%notClickable {
    box-shadow: none !important;
    cursor: default !important;
}

%visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}