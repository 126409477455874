﻿@import '../settings/_config.scss';
@import '../utilities/_mixins.scss';


.listManager {
    margin-bottom: $margin-element;

    .listManager__available {

        @include breakpoint(medium) {
            //padding-right: $padding-element-x;
        }

        @include breakpoint(small only) {
            margin-bottom: $margin-element;
        }

        select {
            $url:"data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+";
            appearance: none;
            background: url($url) no-repeat right 12px center;
            margin-bottom: $margin-element;
        }

    }

    .listManager__selected {

        ul {
            li {
                margin-bottom: 1px;

                .button--mock {
                    display: block;
                    border: 1px solid gray;
                    text-align: left;
                }

            }
        }
    }
}


