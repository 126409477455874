@import '../utilities/_vary.scss';
@import '../utilities/_mixins.scss';

.grid-form {
    border: 1px solid $system-color-border;
    margin-bottom: 1em;
    margin-top: 1em;
    transition: opacity .16s ease-in-out;
    width: 100%;

    @include vary($create: parent) {
        border-color: vary-get('color-border');
    }

    > div {
        &:nth-child(odd) {
            @include vary($create: parent) {
                background-color: vary-get('color-row');
            }
        }
    }

    &.has-header > div:first-child {
        @include vary($create: parent) {
            background-color: vary-get('color-rowhead');
            border-bottom: 1px solid vary-get('color-border');
        }
    }

    button {
        @extend %buttonSystemLight;
    }

    input:not(input[type=checkbox]) {
        width: 100%;

        @media screen and (min-width: 701px) {
            max-width: 40vw;
        }
    }

    label {
        font-weight: 600;
    }

    .row {
        &:not(:empty) {
            padding: 0 $padding-element-x;

            .columns {
                &:not(:empty) {
                    //@include firstAndLastElementMargins;
                    margin: $margin-element 0;

                    > :first-child {
                        margin-top: 0;
                    }

                    > :last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

}
