﻿@import '../settings/_settings.scss';
@import '../utilities/_mixins.scss';
@import '../utilities/_placeholders.scss';

.actionBar {
    background-color: $color-white;

    > .dropdown > span {
        @include borderShadow;
    }

    .button--icon {
        min-height: rem-calc(32px);
        min-width: rem-calc(32px);
    }

    .button,
    .button--mock {
        border-radius: 0;
        padding: 3px;
        text-align: center;
    }

    &--shiftOut {
        margin-right: -$margin-element;
    }

    &.actionBar--rightBottom,
    &.actionBar--right,
    &.actionBar--top-right {
        .dropdown {
            position: absolute;
            right: -1rem;
            top: -1rem;
        }
    }

    &.actionBar--rightTop {
        .dropdown {
            //can't have a dropdown on top unless you can figure out a way
        }
    }

    &.actionBar--top-right {
        margin-top: 2.45rem;
    }

    &.actionBar--rightBottom {
        margin-top: 1.05rem;
    }

    &.actionBar--rightTop {
        margin-top: -1.05rem;
    }

    &:not(.actionBar--expanded) {
        .dropdown {
            display: flex;

            &__content {
                display: flex;
                flex-direction: row;
                right: rem-calc(32px);
            }
        }
    }

    &.actionBar--expanded {
        .dropdown {
            &:focus,
            &:hover {
                // this syles are to give the hover the same width as the options
                text-align: right;
                width: rem-calc(200px);
            }
        }

        .dropdown__content {
            button {
                &:before {
                    padding: 0 .25rem;
                }
            }
        }
    }
}

.actionButton {
    button {

        &.button--icon {
            // causes button to be too far down
            //margin-top: rem-calc(16px);
            min-height: rem-calc(32px);
            min-width: rem-calc(32px);
        }

        &:before {
            background-color: $color-white;
            border-radius: 50%;
        }
    }
}
