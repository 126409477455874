﻿@import '../utilities/_mixins.scss';
@import '../utilities/_placeholders.scss';

.actionResponse {
    padding: $padding-element-side;

    @include fancySection;

    &:first-child {
        margin-top: 0;
    }

    &__deadline {
        > tbody > tr > td {
            background-color: $color-white;
        }

        > tbody > tr:not(:first-child) > td {
            padding-top: 0 !important;
        }
    }

    &__response {
        margin-left: 2 * $margin-element;

        > legend {
            margin-left: -2 * $margin-element;
        }
    }
}


.timeline {
    .removeExtensionContainer {
        margin-bottom: $margin-element;
    }

    h2 {
        margin-bottom: 0;
    }

    .timeline-label {
        margin-top: 0.2rem;
    }

    .timeline-progress {
        background-color: $color-white;
        border: 1px solid $system-color-background;
        width: 90%;
    }

    .input-theme legend:not(:empty) {
        margin-bottom: $margin-element;
    }
}

.timeline-routing {
    .routing-feedback {
        margin-bottom: 2 * $margin-element;
    }
}

.intake {
    .met-requirements {
        padding-top: $padding-element-y;
    }

    .update-all {
        margin-left: $margin-element;
    }
}

.deadline-container {
    margin-bottom: 2 * $margin-element;
    border-bottom: 1px solid $system-color-background;
}

.extension-info {
    vertical-align: top;
    ul {
        margin: 0;
    }
}

.related-mediation-case-numbers {
    margin-bottom: 0;
    margin-top: 0;
    padding-left: 1rem;
}