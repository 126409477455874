.no-bullet {
  margin-#{$global-left}: 0;
  list-style: none;
}

.single-space {
  li,
  li:after {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  li {
    ul,
    ul:after {
      margin-top: 0 !important;
    }
  }
}
