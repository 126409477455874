@import '../settings/_settings.scss';
@import '../vendors/foundation-sites/util/_breakpoint.scss';
@import '../settings/_config';

.theme-bpie {
    .new-dbpie-label {
        text-align: right;
        padding-right: $padding-element-x;
    }

    .resources-glossary-container {
        float: right;
        margin-top: $margin-element * 1.2;
        margin-bottom: $margin-element * 1.2;
    }

    .districtSummary {

        input[type="checkbox"] + label {
            margin-left: 0;
            text-indent: 0;
        }

        &__toggleButton {
            background-color: transparent;
        }
    }

    .table-form {
        &:not(:empty) {
            label {
                padding-bottom: 0.25rem;
            }
        }
    }


    @if map-has-key($breakpoints, medium)or map-has-key($breakpoints, large)or map-has-key($breakpoints, xlarge)or map-has-key($breakpoints, xxlarge) {
        .row {
            &.row-margin-revert {
                margin-left: revert;
                margin-right: revert;
            }
        }
    }

    .priority-indicators-summary input[type="checkbox"] {
        + label {
            margin-left: $margin-element * 3.9;
            text-indent: $margin-element * -3.6;

            &:before {
                margin-left: 0;
                text-indent: 0;
            }
        }
    }

    .bpie-summary-table {
        .htmlDisplay--editable {
            margin-top: 0;
        }
    }

    .bpiePriorityList, .bpieAdminNavigation {
        @extend .navigation-buttons
    }

    .sbpie-summary-table {
        @extend .summary-tables;

        td.actions {
            white-space: nowrap;
        }
    }

    .districtPie-summary {
        .SectionHeader h2 {
            margin: 0;
        }
        .table-grid {
            margin-top: 0;
            margin-bottom: $margin-element * 2;
        }
    }

    .report-header-line {
        border-bottom: $global-radius solid $system-color-foreground;
    }

    .report-indicator-indent {
        margin-left: $margin-element * 1.3;
        text-indent: $margin-element * -1.3;
    }

    .buttonBar--submission-module {
        .feedback textarea {
            height: 7rem;
        }

        .feedback-container #feedback {
            max-height: 10rem;
            overflow-y: auto;
        }
    }
    .summary-status {
        padding: 0.8rem;
    }

    .summary-school-admin {
        margin-top: 0;

        .column {
            padding-right: 0.5rem;
            align-self: center;
            text-align: left;

            legend {
                margin-top: 0;
            }
        }

        &--form {
            .column {
                align-self: flex-end;
                padding-right: 0.5rem;
            }
        }
    }

    .bpie-assessment-summary {
        .column {
            display: flex;

            em {
                margin: auto;
            }

            .delete-sbpie-btn {
                float: right;
            }
        }
    }

    .districtSummary, .dpieGoalsSummary, .dpieProgressMonitoringsSummary {
        td.actions {
            white-space: nowrap;
        }
    }

    .actionStep {
        &--addActionStep {
            margin-top: $margin-element * 2;
        }
        &--info {
            .Collapsible__contentInner {
                margin: 0;

                .table-form {
                    margin: 0;
                }

                &::before, &::after {
                    margin: 0;
                }

                .viewInfo {
                    .row {
                        padding: $padding-element-y $padding-element-x;

                        .help-text {
                            font-size: 0.8em;
                        }

                        .column:first-child {
                            padding-right: $padding-element-x;
                        }
                    }

                    .row:not(:last-child) {
                        border: 0;
                        border-bottom: 1px solid $system-color-border;
                    }
                }
            }
        }

        &--expandCollapse {
            text-align: right;
            .button {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
}