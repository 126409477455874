﻿@import '../settings/_config';
@import '../utilities/_mixins';


.theme-users {
  .user-behaviors {
    table {
      th,
      td {
        text-align: center;

        input[type="checkbox"] {
          + label {
            display: block;
            margin: 0;
            text-indent: 0;
          }
        }
      }

      td:first-child {
        text-align: left;
        padding-left: 2rem;
      }
    }
  }
}