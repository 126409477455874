﻿@import '../settings/_settings.scss';
@import '../utilities/mixins';

.dropdown {
    /*display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;*/

    .dropdown__content {
        transition: $slider-transition;

        @include dropShadow($color-gray, 10px);

        button {
            margin: 0;
        }
    }
}
