﻿
.indexIdentifier {
    display: inline-block;
    list-style-position: inside;
    margin: 0;
    padding-left: 0;
}

.indexIdentifier-- {

    &upper-alpha {
        list-style-type: upper-alpha;
    }

    &upper-roman {
        list-style-type: upper-roman;
    }

    &lower-alpha {
        list-style-type: lower-alpha;
    }

    &lower-roman {
        list-style-type: lower-roman;
    }

    &decimal {
        list-style-type: decimal;
    }
}
