﻿@import '../settings/_config';
@import '../utilities/_mixins.scss';

.theme-login {
    background: #c2dbf4 radial-gradient(circle, #FFF, #c2dbf4) center top;

    ul {
        padding: 0;
        list-style: none;
    }

    .l-page-container {
        display: flex;
        flex-flow: column;
        max-width: $width-page-login;
        margin: auto;
        min-height: 100vh;
        text-align: center;

        header {
            color: $system-color-foreground;
            flex: 0 0 auto;

            h1 {
                font-size: 2rem;
                text-transform: uppercase;
            }
        }

        .logo {
            background: url("../images/logo-gsw.png") no-repeat;
            background-position: center top;
            background-size: contain;
            height: 200px;
            margin: auto;
            width: 70%;

            @include spin(120s);
        }

        main {
            flex: 0 0 auto;

            @media screen and (min-height: 701px) and (min-width: 701px) {
                min-height: 40vh;
                position: relative;

                .align-vertical {
                    @include position(center, center);
                }
            }

            .icon-notification:hover,
            .icon-notification {
                border: none;
                background-color: transparent !important;
            }

            .input-login,
            .input-password,
            button {
                margin: 10px auto;
                width: 100%;
                max-width: 275px;
            }

            input {
                @include clickable($system-color-shadow);
            }
        }
    }
}