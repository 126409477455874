﻿@media print {

    html { font-size: 12px; }

    /*
     * Don't show links that are fragment identifiers,
     * or use the `javascript:` pseudo protocol
     */
    a[href^="#"]:after,
    a[href^="javascript:"]:after {
        content: "" !important;
    }

    pre,
    blockquote {
        border: 1px solid #999;
        page-break-inside: avoid;
    }

    /*
     * Printing Tables:
     * http://css-discuss.incutio.com/wiki/Printing_Tables
     */

    thead {
        display: table-header-group;
    }

    .row,
    tr,
    img {
        page-break-inside: avoid;
    }

    .row,
    img {
        max-width: 100% !important;
    }

    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }

    h2,
    h3 {
        page-break-after: avoid;
    }

    .pageBreak--after {
        page-break-after: always;
    }

    .pageBreak--avoid {
        page-break-inside:avoid;
    }

    footer,
    .input-button {
        display: none;
    }
}
