@import '../utilities/mixins';
/* ==========================================================================
   Helper classes
   ========================================================================== */

/*
 * Hide visually and from screen readers
 */

.is-hidden {
    display: none !important;
}

/*
 * Hide only visually, but have it available for screen readers:
 * http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */

.is-visuallyhidden {
    @include visuallyhidden;
}

/*
 * Extends the .visuallyhidden class to allow the element
 * to be focusable when navigated to via the keyboard:
 * https://www.drupal.org/node/897638
 */

.is-visuallyhidden.focusable:active,
.is-visuallyhidden.focusable:focus {
    @extend %focusable;
}

/*
 * Hide visually and from screen readers, but maintain layout
 */

.is-invisible {
    @include invisible;
}