﻿@import '../settings/_config';
@import '../utilities/_mixins';

.theme-surveys {

    #page-nav-links {
        button {

            &:not(:first-child) {
                border-top: none;
            }
        }
    }
}

.survey-respondent-bulk {

    @media screen and (min-width: 700px) {
        td:first-child {
            width: 85%;
        }
    }

    input[type=file] {
        cursor: pointer;
        margin-right: 1em;

        @extend %icon-attachment;

        &:before {
            @extend %icomoon;
            display: inline-block;
            padding-left: 80px;
        }
    }

    input[type=file]::-webkit-file-upload-button {
        visibility: hidden;
    }
}

.survey-respondents {

    label {
        display: none;
    }

    button {
        &.delete:after {
            content: "delete respondent";

            @include visuallyhidden;
        }

        &.icon-history:after {
            content: "unsubmit respondent survey";

            @include visuallyhidden;
        }

        &:hover + label {
            display: inline;
        }
    }
}

.survey-elements {
    > tbody > tr > td {
        padding: 5px 0;
    }

    .ui-accordion-content {
        position: relative;
    }

    .table-form {
        margin-top: 1.5em;
    }

    @media screen and (min-width: 700px) {
        .table-form {
            td {
                position: relative;

                .l-flex-container {
                    flex-direction: column;
                }

            }

        }
    }
}
