@import '../utilities/_vary';
@import '../utilities/_mixins';
@import '../vendors/foundation-sites/components/_table';
@import '../settings/_config.scss';

$border-hover: 1px dashed $color-gray-medium !important;
$border-system: 1px solid $system-color-border;
$border-vary: 1px solid vary-get('color-border');
$vertical-align-list: "top" "middle" "bottom";

@each $current-align in $vertical-align-list {
	tr.align-#{$current-align} td,
	tr.align-#{$current-align} th,
	td.align-#{$current-align},
	th.align-#{$current-align} {
		vertical-align: #{$current-align};
	}
}

.table-grid,
.table-form {
	margin-bottom: $margin-element;
	margin-top: $margin-element;
	transition: opacity .16s ease-in-out;
	width: 100%;

	thead,
	tfoot,
	tbody {
		position: relative;
		width: 100%;

		td,
		th {
			position: relative;

			&.action-no-wrap {
				width: 10%;
				white-space: nowrap;
				text-align: center;
			}

			&:not(:empty) {
				padding: 0.8rem
			}

			.row + .row {
				margin-top: $margin-element;
			}
		}
	}

	tbody tr {
		&:nth-child(even) {
			@include vary($create: parent) {
				background-color: vary-get('color-row');
			}
		}

		&.highlight-row {
			background-color: $color-highlight-dark !important;
		}

		&.add-row {
			background-color: $color-add-row !important;
			border-bottom: $border-system;
			border-top: $border-system;
		}

		&.edit-row {
			background-color: $color-edit-row !important;
			border-bottom: $border-system;
			border-top: $border-system;
		}
	}

	thead th:not(:empty) {
		@include vary($create: parent) {
			background-color: vary-get('color-rowhead');
			border-top: $border-vary;
		}
	}

	tbody th:not(:empty) {
		@include vary($create: parent) {
			background-color: vary-get('color-rowsubhead');
			border-bottom: $border-vary;
			border-top: $border-vary;
		}
	}

	tfoot,
	.tfoot {
		td {
			@include vary($create: parent) {
				background-color: vary-get('color-rowfooter');
			}

			.l-flex-container {
				align-items: baseline;
			}
		}
	}

	.table__cell--center {
		text-align: center !important;
		align-self: center !important;
	}
}

.table-print {
	margin-top: $margin-element;
	margin-bottom: $margin-element;
	transition: opacity .16s ease-in-out;
	width: 100%;
	border-bottom: none !important;
	display: table !important;

	caption {
		text-align: left;
		font-size: 1.4rem;
		margin-bottom: $margin-element;
		font-weight: bold;
	}

	thead,
	tfoot,
	tbody {
		position: relative;
		width: 100%;
	}

	thead,
	tfoot,
	tbody {
		td,
		th {
			position: relative;

			&:not(:empty) {
				padding: 0.25 * $padding-element-x;
			}

			@include vary($create: parent) {
				border-top: $border-vary;
				border-bottom: $border-vary;
			}
		}
	}

	thead th, tbody th, tfoot td {
		@include vary($create: parent) {
			background-color: vary-get('color-rowhead');
		}
	}

	tfoot td {
		font-weight: bold;
	}

	tbody tr.odd {
		@include vary($create: parent) {
			background-color: vary-get('color-row');
		}
	}
}

.table-print,
.table-grid {

	@include vary($create: parent) {
		border-bottom: $border-vary;
	}

	tbody {
		tr:last-child {
			td {
				@include vary($create: parent) {
					border-bottom: $border-vary;
				}
			}
		}
	}

	td,
	th {
		text-align: left;

		@include vary($create: parent) {
			border-left: $border-vary;
			border-right: $border-vary;
		}

		&:not(:empty) {
			@include breakpoint(medium down) {
				padding: $padding-element-small;
			}

			&.button-cell {
				min-width: rem-calc(133px);
				padding: $padding-button;
			}
		}
	}

	&.hide-last-cell {
		tr {
			td:last-child,
			th:last-child {
				background-color: transparent !important;
				border: none !important;
				overflow: visible;
				padding: 0 !important;
				width: 0 !important;
			}
		}
	}
}

.table-form {

	@include vary($create: parent) {
		border: $border-vary;
	}

	thead th {
		@include vary($create: parent) {
			background-color: vary-get('color-rowhead');
		}
	}

	th {
		text-align: left;

		p {
			font-weight: normal;
		}
	}

	tfoot {
		td {
			@include vary($create: parent) {
				border-top: $border-vary;
			}
		}
	}

	&:not(:empty) {
		label {
			padding-top: 0;
		}
	}
}

.table-scroll .table-print {
	@include table-scroll;
}

.table-print.table-print-center {
	display: table;
	width: auto;
	margin-left: auto;
	margin-right: auto;
}

.table-print.add-padding {
	thead,
	tfoot,
	tbody {
		td,
		th {
			&:not(:empty) {
				padding: $padding-element-x;
			}
		}
	}
}

.table-print {
	tbody {
		td.indent-level-1 {
			&:not(:empty) {
				padding-left: 3 * $padding-element-x;
			}
		}

		td.indent-level-2 {
			&:not(:empty) {
				padding-left: 5 * $padding-element-x;
			}
		}

		td.indent-level-3 {
			&:not(:empty) {
				padding-left: 7 * $padding-element-x;
			}
		}
	}
}

.table-scroll {
	@include table-scroll;

	@include breakpoint($width-page down) {
		.actionButton--right {
			right: 1rem;
		}
	}

	th.is-clickable,
	tr.is-clickable {
		@include clickableHover {
			background-color: $color-highlight !important;
			outline: $border-hover;

			td {
				background-color: $color-highlight !important;
				border-right: $border-hover;
				border-left: $border-hover;
			}
		}
	}

	.table-grid {
		margin-bottom: 0;
	}
}
