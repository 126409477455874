
//A Base rule is applied to an element using an element selector, a descendent selector, or a child selector, along with any pseudo-classes. It doesnt include any class or ID selectors. It is defining the default styling for how that element should look in all occurrences on the page.
//Base styles include setting heading sizes, default link styles, default font styles, and body backgrounds. There should be no need to use !important in a Base style.

@import 'settings/_settings.scss';
@import 'settings/_config';

html {
    overflow-x: hidden; //zoomed elements creating scrollbars
}

*,
*:before,
*:after {
    box-sizing: border-box;
}

li {
    &:not(:last-child) {
        @include lastElementMargins;
    }
}

p {
    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }

    &:empty {
        display: none;
    }
}

body {
    font-family: Arial, sans-serif;
    font-size-adjust: 0.58;
}

section {
    position: relative;

    &:not(:first-child) {
        margin-top: $margin-element;
    }

    &:not(:last-child) {
        margin-bottom: $margin-element;
    }
}

header {
    position: relative;
}

input:-webkit-autofill {
    padding-left: 10px;
}

ol,
ul {
    margin-bottom: $global-margin;
    margin-top: $global-margin;
}

ol {
    list-style-type: decimal;

    ol {
        list-style-type: lower-alpha;

        ol {
            list-style-type: lower-roman;

            ol {
                list-style-type: decimal;
            }
        }
    }

    &[type="A"] {
        list-style-type: upper-alpha;

        ol {
            list-style-type: decimal;
        }
    }

    &[type="a"] {
        list-style-type: lower-alpha;

        ol {
            list-style-type: decimal;
        }
    }
}
