.draggable-area {
  padding: 4px 48px 4px 16px;
  margin: 16px 0;
  border: 1px solid lightgrey;
  color: #000;
}

.draggable-container {
  border: 1px dotted grey;
  padding: 8px;
  width: 100%;
}