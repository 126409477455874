@import '../settings/_config.scss';
@import '../utilities/_mixins.scss';

.notification {
    //needed only if positioning is not used from base
    //@include position(right, center);
    .notification__message {
        background-color: $color-alert;
        border-color: $color-alert;
        color: $color-white;
        text-align: center;

        @include borderShadow($color-black);
        //needed only if positioning is not used from base
        //@include position(none, center);
        &:after {
            border-color: transparent transparent transparent $color-alert;
        }
    }

    &__settings {
        .notification__legend {
            margin-bottom: $margin-element;
        }

        .input-theme__checkbox .columns{
            padding-left: $padding-element-x;
        }

        .notification__buttons {
            margin-bottom: $margin-element * 4;
        }
    }
}

