﻿@import '../settings/_config.scss';
@import '../settings/_settings.scss';
@import '../utilities/_mixins.scss';
@import '../utilities/_placeholders.scss';

.filterBar {
    border: 1px solid $system-color-border;
    margin-bottom: $global-margin;

    @include firstAndLastElementMargins;

    > .columns {
        margin-top: $global-margin;
    }

    @include hover {
        @include hoverColor($system-color-shadow);
    }

    &::before {
        content: "Filter by: ";
        @include visuallyhidden;
    }

    .columns {
        @include grid-column-gutter($padding-element-x, $padding-element-x);
    }

    @media print {
        display: none;
    }
}
