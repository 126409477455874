@import '_vary.scss';
@import '../settings/_config.scss';

@mixin position($horizontal: center, $vertical: center) {
	position: absolute;
	z-index: 100;
	//transform
	@if ($horizontal != "none" and $vertical != "none") {
		@if ($horizontal != "right" and $vertical != "bottom") {
			transform: translate(-50%, -50%);
		}

		@if ($horizontal == "right" and $vertical == "bottom") {
			transform: translate(50%, 50%);
		}

		@if ($horizontal != "right" and $vertical == "bottom") {
			transform: translate(-50%, 50%);
		}

		@if ($horizontal == "right" and $vertical != "bottom") {
			transform: translate(50%, -50%);
		}
	} @else if ($horizontal != "none") {
		@if ($horizontal != "right") {
			transform: translate(-50%, 0);
		}

		@if ($horizontal == "right") {
			transform: translate(50%, 0);
		}
	} @else if ($vertical != "none") {
		@if ($vertical == "bottom") {
			transform: translate(0, 50%);
		}

		@if ($vertical != "bottom") {
			transform: translate(0, -50%);
		}
	}
	//horizonal
	@if ($horizontal == "center") {
		left: 50%;
	} @else if ($horizontal == "right") {
		right: 0;
	} @else if ($horizontal == "left") {
		left: 0;
	}
	//vertical
	@if ($vertical == "center") {
		top: 50%;
	} @else if ($vertical == "top") {
		top: 0;
	} @else if ($vertical == "bottom") {
		bottom: 0;
	}
}

@mixin roundedCorners($radius: $border-radius) {
	border-radius: $radius;
	border-collapse: separate;

	li:first-child > a {
		border-top-left-radius: $radius;
		border-top-right-radius: $radius;
	}

	li:last-child > a {
		border-bottom-left-radius: $radius;
		border-bottom-right-radius: $radius;
	}
}

@mixin dropShadow($color: '', $spread: 20px) {
	z-index: 10;
	@if ($color != '') {
		box-shadow: 0px 0px $spread 0px $color;
	} @else {
		@include vary($create: parent) {
			box-shadow: 0px 0px $spread 0px vary-get('color-shadow');
		}
	}
}

@mixin stickable($top: true, $minHeight: 700px) {

	@include borderShadow;

	@media (min-height: $minHeight) {
		@include breakpoint(medium) {
			position: -webkit-sticky; /* Safari */
			position: sticky;
			z-index: 500;

			@if ($top) {
				top: .5rem;
			} @else {
				bottom: .5rem;
			}

			@include stickyShadow;
		}
	}
}

@mixin stickyShadow($color: $color-shadow, $spread: 40px) {

	@include dropShadow($color, $spread);
}

@mixin borderShadow($color: $color-shadow, $spread: 5px) {

	@include dropShadow($color, $spread);
}

@mixin clickOutline($color: '') {
	&:not(.is-disabled):not(:disabled) {
		@if ($color != '') {
			outline: 1px solid $color;
		} @else {
			@include vary($create: parent) {
				outline: 1px solid vary-get('color-shadow');
			}
		}
	}
}

@mixin clickable($color: '') {
	@include clickableHover {
		@include hoverColor($color);
	}
}

@mixin hover {
	&:focus,
	&:hover {
		@content;
	}
}


@mixin enabled {
	&:not(.is-disabled):not(:disabled) {
		@content;
	}
}

@mixin clickableHover {
	@include hover {
		@include enabled {
			cursor: pointer;
			@content;
		}
	}
}

@mixin hoverColor($color: '') {
	@include enabled {
		transition: all .1s ease-in-out;

		@if ($color != '') {
			@include dropShadow($color);
		} @else {
			@include dropShadow;
		}
	}
}

@mixin graphicStar($color: gold(.95)) {
	background: $color url("../images/graphic-star.png") center center no-repeat;
	background-size: contain;
}

@mixin buttonJustText($color: $color-link) {
	background: none;
	border: none;

	&:enabled {
		color: $color;
	}
}

@mixin buttonLink($color: $color-link) {
	@include buttonJustText($color);

	span {
		&:disabled {
			opacity: $opacity-disabled;
		}

		&:not(:disabled) {
			cursor: pointer;
			text-decoration: underline;
		}
	}
}

/*media query placeholders*/
@mixin _visuallyhidden {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

@mixin visuallyhidden($print: false) {


	@if ($print) {
		@include _visuallyhidden;
	} @else {
		@media screen {
			@include _visuallyhidden;
		}
	}
}

@mixin visuallyunhidden {
	border: unset;
	clip: unset;
	height: unset;
	margin: unset;
	overflow: unset;
	padding: unset;
	position: unset;
	width: unset;
}

@mixin visible {
	opacity: 1;
	visibility: visible;
	transition: opacity .15s ease-in-out;
}

@mixin invisible {
	opacity: 0;
	visibility: hidden;
	transition: opacity .15s ease-in-out;
}

@mixin spin($duration: 2s) {
	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}

		100% {
			transform: rotate(360deg);
		}
	}

	animation: spin $duration infinite linear;
}

@mixin fancySection {

	&:not(:last-child) {
		margin-top: $margin-element;
	}

	&:not(:last-child) {
		margin-bottom: $margin-element * 2;
	}

	@include firstAndLastElementMargins;


	@media screen {
		@include borderShadow;
		@include roundedCorners;

		@include vary($create: parent) {
			background-color: vary-get('color-row');
			border-bottom: 4px solid vary-get('color-border');
		}
	}
}

@mixin firstAndLastElementMargins($ems: 1) {
	@include firstElementMargins($ems);
	@include lastElementMargins($ems);
}

@mixin firstElementMargins($ems: 1) {
	&:before {
		content: '';
		display: table;
		width: 100%;
		margin-bottom: $ems * $global-margin;
	}
}

@mixin lastElementMargins($ems: 1) {
	&:after {
		content: '';
		display: table;
		width: 100%;
		margin-top: $ems * $global-margin;
	}
}