﻿@import '../utilities/_vary';
@import '../utilities/_mixins';
@import '../settings/_settings.scss';

.elementSet {

    @include vary($create: parent) {
        border: 1px solid vary-get('color-border');
    }

    .element {
        &:not(:first-child) {
            margin-top: $global-margin;
        }

        p:first-child {
            margin-top: $margin-element;
        }


        > .row {

            @include vary($create: parent) {
                background-color: vary-get('color-row');
            }

            padding: $global-padding;
        }

        > .htmlDisplay {
            padding-left: $global-padding;
            padding-right: $global-padding;

            h3 {
                text-decoration: underline;
            }
        }
    }
}
