@import '../settings/_config';
@import '../utilities/vary';
@import '../utilities/mixins';

.progress-meter-text {
    color: black;
    font-size: inherit;
    position: relative;
    text-align: center;
}

.progress {
    background-color: $color-gray-light;

    .progress-meter {
        transition: all 500ms ease-in-out;

        @include vary($create: parent) {
            background-color: vary-get('color-progress');
        }
    }

    .progress-meter-text {
        position: absolute;
        z-index: 10;
    }
}
.progress-complete {
    margin: auto;
    text-align: center;
    max-width: $width-message;

    .graphic-star {
        margin: auto;
        padding-top: 30%;
        width: 30%;

        @include graphicStar;
        @include vary($create: parent) {
            background-color: vary-get('color-background');
        }
    }
}