﻿@import '../settings/_config.scss';
@import '../settings/_settings.scss';
@import '../utilities/_placeholders.scss';

.row {
    padding-left: 0;
    padding-right: 0;
    &__space {
        padding-top: $padding-element-y;
        padding-bottom: $padding-element-y;
    }
    &__space-right {
        padding-right: $padding-element-x-xsmall !important;
    }
}

.columns {
    @include grid-column-gutter(0, 0);
}
