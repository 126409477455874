﻿@import '../utilities/_placeholders.scss';

.RichTextEditor {
    &__advanced {
        .k-editor {
            &-content {
                p {
                    display: inline;
                    font-size: 1rem;
                    line-height: 1.5;
                }

                h6 {
                    font-size: 1rem;
                    font-weight: normal;
                    line-height: 1.5;
                }
            }
        }
    }

    .k-editor {
        color: #000;
    }

    .k-dropdown {
        width: 12rem;
    }

    &__hover {
        @include firstAndLastElementMargins;
        @include hoverColor;

        border-radius: 3px;
    }
}

%border {
    border: 1px solid $light-gray;
    border-radius: 3px;
    &:not(:only-child) {
        margin: $margin-element 0;
    }
}

.htmlDisplay {
    transition: opacity .16s ease-in-out;

    &--editable:not(:empty) {
        @extend %border;
        @include firstAndLastElementMargins;

        padding: 0 $padding-element-x;
    }
    &.htmlDisplay--truncated:not(:empty) {
        @extend %border;
        @include firstAndLastElementMargins(.5);

        background-color: transparent;
        padding: 0 $padding-element-x-small;
        text-align: left;

        .--isTruncated:after {
            content: "...";
        }
    }
}
