﻿@import '../settings/_config.scss';
@import '../settings/_settings.scss';
@import '../utilities/_placeholders.scss';

.theme-spp {

    .Collapsible {

        .row {
            background: transparent !important;
            border: none !important;
            padding: 0;
        }

        .icon-pencil {
            min-height: unset;
            min-width: unset;
            padding-bottom: 0;
            padding-top: 0;
        }
    }

    .spp-legislative-changes {
        background-color: $color-highlight;
    }

    .filter-part-section {
        .columns {
            text-align: left;
            padding-left: $padding-element-x / 2;
            padding-right: $padding-element-x / 2;
        }
    }
}

.sppAdminContainer {
    .actionBar {
        &.actionBar--top-right {
            margin-top: 0.75rem;
        }
    }
}
