﻿@import '../utilities/_mixins.scss';

.actionButton,
.actionBar {

    &--top {
        @include position(center, top);
    }

    &--top-right {
        @include position(right, top);
        z-index: 200;
    }

    &--bottom {
        @include position(center, bottom);
    }

    &--right {
        @include position(right, center);
        z-index: 200;
    }

    &--rightBottom {
        @include position(right, center);
        z-index: 200;
    }

    &--rightTop {
        @include position(right, center);
        z-index: 200;
    }

    &--left {
        @media screen and (min-width: 701px) {
            @include position(left, center);
        }
    }

    @media print {
        display: none;
    }

    &.actionBar--expanded {
        .dropdown__content {
            width: 100%;

            .button {
                display: block;
                text-align: left;
                width: 100%;
            }
        }
    }
}