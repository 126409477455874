﻿@import '../utilities/_vary';
@import '../utilities/_mixins';
@import '../settings/config';

.Collapsible__trigger.is-open + .Collapsible__contentOuter {
    border: 1px solid grey;
    border-top: 0;
}

.Collapsible__trigger {
    cursor: pointer;
    display: block;
    position: relative;

    &.is-disabled {
        cursor: unset;
    }
}
