﻿.listManager {
    .listManager__selected {

        ul {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                display: block;
                margin: 0;
                position: relative;

                &:not(:first-child) {
                    border-top: none;
                }
            }
        }
    }
}
