.tabs {
  margin: 0;
  padding: 0;
  .tabs-title {
    padding: 0;
    margin: 0;

    &:after {
      padding: 0;
      margin: 0;
    }

    > a {
      font-size: 1rem;
      color: $color-black;
      padding-bottom: $padding-element-y * 1.5;
    }

    > a:hover {
      background-color: $color-gray-light;
      color: $color-black;
    }

    &.is-active {
      display: inline-block;
      background-color: $color-gray-light;
    }
  }
}

.tabs-content {
  .is-active {
    display: block;
  }
}