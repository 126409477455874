﻿@import '../settings/_config';

.tileContainer {
    flex-flow: row wrap;
    justify-content: flex-start;

    &__tile {
        background-color: $color-white;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        text-align: center;
        width: 100%;

        &__content {
            flex-grow: 5;
        }
    }

    @media print {
        page-break-inside: avoid;
    }
}
