﻿@import '../settings/_config';
@import '../utilities/_mixins.scss';
@import '../utilities/_vary';



.banner {
    animation: animatedBackground 30s linear infinite;
    background: #6fc731 url("../images/background-header.png") left top repeat-y;
    background-position: 0px 0px;
    padding-bottom: 5px;

    @keyframes animatedBackground {
          0% { background-position: 0 0; }
          50% { background-position: 100% 0; }
          100% { background-position: 0 0; }
    }


    .banner__gradient {
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#bddcf5+0,bfdcf5+23,cfe5f8+31,f0f7fc+44,fafcfe+49,fcfdff+52,fbfdfe+54,f2f8fd+59,cfe5f8+73,bfdcf5+81,bddcf5+100 */
        background: rgb(189,220,245); /* Old browsers */
        background: -moz-linear-gradient(left, rgba(189,220,245,1) 0%, rgba(191,220,245,1) 23%, rgba(207,229,248,1) 31%, rgba(240,247,252,1) 44%, rgba(250,252,254,1) 49%, rgba(252,253,255,1) 52%, rgba(251,253,254,1) 54%, rgba(242,248,253,1) 59%, rgba(207,229,248,1) 73%, rgba(191,220,245,1) 81%, rgba(189,220,245,1) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left, rgba(189,220,245,1) 0%,rgba(191,220,245,1) 23%,rgba(207,229,248,1) 31%,rgba(240,247,252,1) 44%,rgba(250,252,254,1) 49%,rgba(252,253,255,1) 52%,rgba(251,253,254,1) 54%,rgba(242,248,253,1) 59%,rgba(207,229,248,1) 73%,rgba(191,220,245,1) 81%,rgba(189,220,245,1) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right, rgba(189,220,245,1) 0%,rgba(191,220,245,1) 23%,rgba(207,229,248,1) 31%,rgba(240,247,252,1) 44%,rgba(250,252,254,1) 49%,rgba(252,253,255,1) 52%,rgba(251,253,254,1) 54%,rgba(242,248,253,1) 59%,rgba(207,229,248,1) 73%,rgba(191,220,245,1) 81%,rgba(189,220,245,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#bddcf5', endColorstr='#bddcf5',GradientType=1 ); /* IE6-9 */
        .l-flex-container {
            color: $system-color-foreground;
            align-items: center;
            justify-content: space-between;
            margin: auto;
            max-width: $width-page;
        }

        .banner__title {
            height: 4em;
            max-width: 100%;
            text-align: center;
            vertical-align: middle;

            .h1 {
                font-size: 1.6em;
                font-weight: bold;
                line-height: 1em;
                margin: 0;
                text-align: center;
                text-transform: uppercase;

                @media screen and (max-width: 500px) {
                    font-size: 1.25em;
                }
            }

            .h2 {
                font-size: .77em;
                font-weight: normal;
                line-height: 1em;
                margin: 0;

                @media screen and (max-width: 720px) {
                    @include visuallyhidden;
                }
            }
        }

        .banner__logo {
            background: url("../images/logo-gsw.png") no-repeat;
            background-size: contain;
            background-position: right center;

            @media screen and (min-width: 500px) {
                height: 3em;
                width: 3em;
            }
        }

        .menu-counterweight {
            padding: 8px;

            &:after {
                content: '';
                display: block;
                width: 34px;
            }
        }
    }

    @media print {
        & {
            display: none;
        }
    }
}

#page-title {
    margin: -1.2rem 0 1.2rem;

    .split-header {
        @include vary($create: parent) {
            border-bottom: 3px solid vary-get('color-background');
        }
    }

    .header {
        &-center {
            text-align: center;
        }
        &-right {
            text-align: right;
        }
    }

    h1 {
        margin: 0;
    }
}