﻿@import '../utilities/_icons.scss';
@import '../utilities/_placeholders.scss';

.button {
	@extend %buttonBase;

	.buttonWrapper + &,
	.button + & {
		margin-left: 2rem;
	}

	&--external {
		text-decoration: none;
	}

	&.button--clickableDiv {
		border: none;
		display: block;
		padding: 0;
		width: 100%;
	}

	//system light
	&--exit,
	&--search,
	&--history,
	&--configure,
	&--needsReview,
	&--publish,
	&--print,
	&--email,
	&--download,
	&--cancel,
	&--edit,
	&--content,
	&--impersonate,
	&--replace,
	&--order,
	&--insertBefore,
	&--insertAfter,
	&--first,
	&--previous,
	&--next,
	&--last,
	&--back,
	&--view,
	&--info,
	&--secondary,
	&--system {
		&:not(.button--round) {
			@extend %buttonSystemLight;
		}
	}

	//system dark
	&--forward,
	&.button--primary {
		@extend %buttonSystem;
	}

	//green dark
	&--submit {
		background-color: $color-add-item-dark !important;
		color: $color-white !important;

		@include clickable($color-add-item-dark);
	}

	//green light
	&--add,
	&--reviewed,
	&--clone,
	&--save,
	&--upload {
		border-color: currentColor !important;
		color: $color-add-item-dark !important;

		@include clickable(currentColor);
	}

	//red
	&--restrict,
	&--needsRevision,
	&--reset,
	&--remove,
	&--delete {
		border-color: currentColor !important;
		color: $color-delete !important;

		@include clickable(currentColor);
	}

	//Blue
	&--exit {
		border-color: currentColor !important;
		color: $system-color-foreground !important;

		@include clickable(currentColor);
	}

	&--clear {
		color: $system-color-foreground !important;
	}

	&:disabled,
	&.is-disabled {
		background-color: $color-disabled-light !important;
		color: $color-disabled-dark !important;
		&::before {
			color: $color-disabled-dark !important;
		}
	}

	&--hover {
		@include clickable(currentColor);
	}

	&--icon {
		/* figure out how to maginify when no label
		padding: 0 !important;

		&:before {
			font-size: rem-calc(24px);
		}
		*/
		span:not(:empty) {
			display: inline-block;
			padding-left: $padding-element-small-top;

			@include breakpoint(small only) {
				@include visuallyhidden;
			}
		}
	}

	&.button--round:hover,
	&.button--round:focus,
	&.button--round {
		&:not(:disabled) {
			border-style: solid;
			border-width: 2px !important;
		}

		align-items: center;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		outline: none;
		padding: 0;
	}

	&.button--mock {
		background-color: transparent;
	}

	&.button--context {
		@extend %buttonLight;
	}

	&.button--link {
		@include buttonLink();

		&.--inline {
			display: inline;
			padding: 0 .25em;
		}
	}

	&.button--menu {
		background-color: transparent;
		color: transparent;
		cursor: pointer;
		height: 60px;
		padding-bottom: 27px;
		max-width: 50px;
		width: 100%;

		span,
		span:before,
		span:after {
			background: $system-color-background;
			border-radius: 1px;
			content: '';
			display: block;
			font-size: 0;
			height: 5px;
			position: relative;
			transition: all 500ms ease-in-out;
		}

		span:before {
			top: 10px;
		}

		span:after {
			bottom: -15px;
		}

		&.is-active {
			span {
				background-color: transparent;
				height: 0;
			}

			span:before {
				transform: rotate(45deg);
				top: 8px;
			}

			span:after {
				top: 3px;
				transform: rotate(-45deg);
			}
		}
	}

	&.button--reset {
		&:before {
			@extend %icon-reset;
		}
	}

	&.button--delete {
		&:before {
			@extend %icon-delete;
		}
	}

	&.button--remove {
		background-color: $color-white !important;

		&:before {
			@extend %icon-remove;
		}
	}

	&.button--add {
		&:before {
			@extend %icon-add;
		}
	}

	&.button--upload {
		&:before {
			@extend %icon-upload;
		}
	}

	&.button--download {
		&:before {
			@extend %icon-download;
		}
	}

	&.button--first {
		&:before {
			@extend %icon-first;
		}
	}

	&.button--previous {
		&:before {
			@extend %icon-previous;
		}
	}

	&.button--next {
		&:before {
			@extend %icon-next;
		}
	}

	&.button--last {
		&:before {
			@extend %icon-last;
		}
	}

	&.button--edit {
		&.button--round {
			color: $system-color-foreground;
		}

		&:before {
			@extend %icon-edit;
		}
	}

	&.button--content {
		&.button--round {
			color: $system-color-foreground;
		}

		&:before {
			@extend %icon-content;
		}
	}

	&.button--impersonate {
		&.button--round {
			color: $system-color-foreground;
		}

		&:before {
			@extend %icon-impersonate;
		}
	}

	&.button--replace {
		&.button--round {
			color: $system-color-foreground;
		}

		&:before {
			@extend %icon-replace;
		}
	}

	&.button--order {
		&:before {
			@extend %icon-order;
		}
	}

	&.button--insertBefore {
		&:before {
			@extend %icon-insertBefore;
		}
	}

	&.button--insertAfter {
		&:before {
			@extend %icon-insertAfter;
		}
	}

	&.button--configure {
		&:before {
			@extend %icon-configure;
		}
	}

	&.button--save {
		&:before {
			@extend %icon-save;
		}
	}

	&.button--small {
		background-color: transparent !important;
		border: none !important;

		&:before {
			@extend %buttonSmall;
		}
	}

	&.button--cancel {
		&:before {
			@extend %icon-cancel;
		}
	}

	&.button--clear {
		&:before {
			@extend %icon-exit;
		}
	}

	&.button--inputAction {
		background-color: $color-white;
		margin-right: -1.3rem;
		outline: none !important;
		transform: scale(0.75) !important;

		@include position(right, top);
	}

	&.button--clear {
		margin-right: 0;
	}

	&--vertical-margin {
		margin: $margin-element 0;
	}

	&.button--clear-response-for-radio-button-list {
		position: inherit;
		float: left;
		margin-top: $margin-element * .5;
		margin-left: $margin-element;
	}

	&.button--exit {
		background-color: $color-white !important;
		margin-right: -1rem;
		margin-top: -1rem;
		outline: none !important;
		transform: scale(0.75) !important;

		@include position(right, top);

		&:before {
			@extend %icon-exit;
		}
	}

	&.button--clone {
		&:before {
			@extend %icon-clone;
		}
	}

	&.button--needsReview {
		&:before {
			@extend %icon-needsReview;
		}
	}

	&.button--needsRevision {
		&:before {
			@extend %icon-needsRevision;
		}
	}

	&.button--reviewed {
		&:before {
			@extend %icon-reviewed;
		}
	}

	&.button--back {
		&:before {
			@extend %icon-back;
		}
	}

	&.button--forward {
		&:after {
			@extend %icon-forward;
		}

		span:not(:empty) {
			padding-right: $padding-element-small-top;
		}
	}

	&.button--helpText {
		&:before {
			background-color: $color-white;
			color: $color-black;

			@extend %icon-notification;
			@include borderShadow($color-black);
		}
	}

	&.button--notification {
		background: none;
		&:before {
			background-color: $color-white;
			color: $color-alert;

			@extend %icon-notification;
			@include borderShadow($color-black);
		}
	}

	&.button--history {
		&:before {
			@extend %icon-history;
		}
	}

	&.button--restrict {
		&:before {
			@extend %icon-restrict;
		}
	}

	&.button--search {
		&:before {
			@extend %icon-search;
		}
	}

	&.button--print {
		&:before {
			@extend %icon-print;
		}
	}

	&.button--view {
		&:before {
			@extend %icon-view;
		}
	}

	&.button--checked {
		&:before {
			@extend %icon-checkbox-checked;
		}
	}

	&.button--unchecked {
		&:before {
			@extend %icon-checkbox-unchecked;
		}
	}

	&.button--info {
		&:before {
			@extend %icon-info;
		}
	}

	&.button--large {
		background-color: $color-add-item-light;
		border-style: dashed;
		border-color: $color-add-item-dark;
		color: $color-add-item-dark;
		font-size: 1.1rem;
		width: 95%;
		padding: $padding-element;
		margin: $margin-element;
	}
}
