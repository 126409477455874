﻿@import '../settings/_settings.scss';
@import '../utilities/_placeholders.scss';

.pageNav {
    .pageNav__links {



        .pageNav__links__sticky {
            @include stickable;
        }

        button {
            border-radius: 0px;
            display: block;
            margin-left: 0;
            width: 100%;

            @extend %buttonLight;

            &:not(:first-child) {
                border-top: none !important;
            }
        }
    }

    .pageNav__content {
        margin-top: $global-margin;

        &:not(.xlarge-) {
            @include breakpoint(xlarge) {
                margin-top: 0;
                padding-left: $global-margin;
            }
        }

        &:not(.large-) {
            @include breakpoint(large) {
                margin-top: 0;
                padding-left: $global-margin;
            }
        }

        &:not(.medium-) {
            @include breakpoint(medium) {
                margin-top: 0;
                padding-left: $global-margin;
            }
        }


        &.large-12,
        &.medium-12,
        &.small-12 {
        }
    }
}