@import '../utilities/_mixins.scss';
@import '../utilities/_placeholders.scss';

.dueProcessAction {
  padding: $padding-element-side;

  @include fancySection;

  &:first-child {
    margin-top: 0;
  }

  &__response {
    > tbody > tr > td {
      background-color: $color-white;
    }

    > tbody > tr:not(:first-child) > td {
      padding-top: 0 !important;
    }
  }
}

.dueProcess {
  &__district-list {
    th.resolution-process,
    td.resolution-process {
      @include vary($create: parent) {
        background-color: scale-color(vary-get('color-rowhead'), $lightness: 50%);
      }
    }

    th.resolution-process {
      @include vary($create: parent) {
        border-bottom: 1px solid vary-get('color-border');
      }
    }
  }
}

.dueProcessContainer {
  .actionBar {
    &.actionBar--top-right {
      margin-top: 0.4rem;
    }
  }
}

.activityTimeline {
  h2 {
    margin-bottom: 0;
  }

  .input-theme legend:not(:empty) {
    margin-bottom: $margin-element;
  }
}