@import '../settings/_config.scss';
@import '../utilities/_placeholders.scss';
@import '../utilities/_mixins.scss';

.RichTextEditor {
  &.other-text {
    margin-left: $margin-element * 2;
  }
}

.monthYear {
  legend { margin-top: 0 !important; }
  &--month {
    margin-right: $margin-element !important;
  }
}

div[class^='form-list-indent-'] label.input-layout-label,
div[class*=' form-list-indent-'] label.input-layout-label,
legend[class^='form-list-indent-'],
legend[class*='form-list-indent-'] {
  display: inline-block;
}

@mixin indent($number-of-chars) {
  @if ($number-of-chars == 1) {
    @include indent-css(0.8rem, - 1.2rem);
  }
  @if ($number-of-chars == 2) {
    @include indent-css(1.6rem, - 2.0rem);
  }
  @if ($number-of-chars == 3) {
    @include indent-css(2.2rem, - 2.6rem);
  }
  @if ($number-of-chars == 4) {
    @include indent-css(2.6rem, - 3.0rem);
  }
}

@mixin indent-css($margin-left, $text-indent) {
  margin-left: $margin-left;
  text-indent: $text-indent;
}

.form-list-indent- {
  &1 .input-layout-label {
    @include indent(1);
  }

  &2 .input-layout-label {
    @include indent(2);
  }

  &3 .input-layout-label {
    @include indent(3);
  }

  &4 .input-layout-label {
    @include indent(4);
  }
}

legend.form-list-indent- {
  &1 {
    @include indent(1);
  }

  &2 {
    @include indent(2);
  }

  &3 {
    @include indent(3);
  }

  &4 {
    @include indent(4);
  }
}

.input-theme {
  label {
    display: none;

    &:not(:empty) {
      display: block;

      .help-text {
        font-size: .75em;
      }
    }
  }

  //input fields
  legend:not(:empty) {
    margin-top: $margin-element;
    padding: 0;

    &.shrink-legend {
      float: left;
    }
    .is-dangerouslySetHtml {
      margin: 0;
    }
  }



  .options-with-clear-response {
    float: left;
    clear: left;
  }

  .select-all {
    margin-bottom: $margin-element !important;
  }

  div.input,
  input[type="number"],
  input[type="text"],
  input[type="password"],
  select,
  textarea {
    background-color: $color-white;
    border-color: $color-gray;
    border-radius: $border-radius;
    border-width: 1px;
    border-style: solid;
    box-sizing: border-box;
    display: block;
    padding: 10px;
    min-height: rem-calc(42px);
    //margin-bottom: $margin-element;
    max-width: 100%;
    width: 100%;

    &.input--mock,
    &[readonly],
    &:disabled {
      background-color: $color-disabled-light;
    }
  }

  select {
    //padding: 10px 7px;
  }

  textarea {
    height: 12rem;
  }

  input[type="number"] {
    text-align: right;
    //$max-width: $width-page * 1 / 7;
    //@media screen {
    //  max-width: $max-width;
    //}
  }

  input[type="password"] {
    $max-width: $width-page * 4 / 7;
    @media screen {
      max-width: $max-width;
    }
  }

  .input-theme__checkbox {
    + .input-theme__checkbox.row {
      margin-top: 0;
    }

    &--horizontal {
      display: inline-block;
      margin-right: .4rem;
    }
  }

  input[type="checkbox"] {
    @include visuallyhidden(true);

    &[readonly],
    &:disabled {
      & + label {
        cursor: default;

        &:before {
          opacity: $opacity-disabled;
        }
      }
    }

    &:focus + label {
      @include clickOutline;
    }

    + label {
      cursor: pointer;
      font-weight: normal;
      @extend %hangingIndent;

      &:before {
        @extend %icon-checkbox-unchecked;
      }

      &.--flush:before {
        margin-left: -0.4rem;
      }

      span {
        margin-left: 0.25rem;
      }
    }


    &:checked + label {
      &:before {
        @extend %icon-checkbox-checked;
      }
    }
  }

  .input-theme__radio {
    &--horizontal {
      > div {
        display: inline-block;
        margin-right: .4rem;
      }
    }
  }

  input[type="radio"] {
    @include visuallyhidden(true);

    &[readonly],
    &:disabled {
      & + label:before {
        opacity: $opacity-disabled;
      }
    }

    &:focus + label {
      @include clickOutline;
    }

    + label {
      display: block;
      font-weight: normal;
      padding: 0;
      //this might not be able to use a generic indent
      //@extend %hangingIndent;
      margin-left: 2rem;
      text-indent: -0.8rem;


      &:before {
        padding-right: 0.5rem;

        @extend %icon-radio-unchecked;
      }
    }

    &:checked + label {
      &:before {
        @extend %icon-radio-checked;
      }
    }
  }

  &.button-approvals {
    .input-theme__radio {
      text-align: center;

      &__option {
        margin: $margin-element $margin-element 0 $margin-element;
        display: inline-block;

        input[type="radio"] {
          + label {
            margin: 0;
            padding: 0;
            text-indent: 0;
            line-height: 0;
            cursor: pointer;
            display: inline-block;

            span {
              @extend .is-visuallyhidden;
            }

            &:before {
              margin: 0;
              padding: 0;
              line-height: 0;
            }

            &.Approved:before {
              content: url("../images/btn-approve.png");
            }

            &.NotApproved:before {
              content: url("../images/btn-reject.png");
            }
          }

          &:checked + label {
            &.Approved:before {
              content: url("../images/btn-approve-selected.png");
            }

            &.NotApproved:before {
              content: url("../images/btn-reject-selected.png");
            }
          }
        }
      }
    }
  }

  input[type="file"] {
    @include visuallyhidden(true);

    &:focus + label {
      @include clickOutline;
    }

    + label {
      font-weight: normal;

      @extend %buttonBase;
    }
  }

  .input-login,
  .input-password {
    padding: 0;

    input {
      background: $color-white url("../images/icon-login.png") center left no-repeat;
      background-size: contain;
      border-color: black(.4);
      padding-left: 60px;

      &:-webkit-autofill {
        padding-left: 10px;
      }
    }
  }

  .input-password {
    input {
      background-image: url("../images/icon-password.png");
    }
  }

  .input--small {
    .input--small__container {
      display: inline-block;
      position: relative;

      .input {
        max-width: 100%;
        min-width: 15rem;
      }
    }
  }

  .flatpickr-input.input {
    margin-bottom: 6.4px; // additional height to match other/mock inputs
  }
}

div.date-column.columns {
  padding-left: 0;
}

div.date-select-custom.columns {
  margin-top: $margin-element;
  padding-left: 0;
}

.input-icon {
  position: relative;

  &.--dollar .input-icon__icon {
    left: 3px;

    &:before {
      content: "$";
    }
  }

  &.--percent {
    input:not(:focus):not(:hover),
    input:read-only,
    input:disabled {
      + .input-icon__icon {
        right: -2px;

        &:before {
          content: "%";
        }
      }
    }
  }

  .input-icon__icon {
    display: block;
    pointer-events: none;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(0, -50%);
    width: 25px;
  }
}