$color-training: #932607;
$color-training-light: rgba(140, 70, 43, 0.1);
$color-product: #32981A;
$color-product-light: rgba(50, 152, 26, 0.1);
$color-serviceDelivery: #AD6200;
$color-serviceDelivery-light: rgba(173, 98, 0, 0.1);
$color-border: rgb(168, 194, 222);

.theme-pts {
  .input-center {
    .columns:not(.text-left) {
      input[type="text"] {
        text-align: center;
      }
    }
  }

  .reports {
    margin-bottom: $margin-element * 4;
    margin-top: $margin-element * 3;

    .columns {
      padding-right: $padding-element-x;
    }

    .columns:last-child {
      align-self: flex-end;
    }
  }

  @mixin changeCollapsibleColor($color, $color-light) {
    .Collapsible__trigger.is-closed {
      border-color: $color-light;
      background-color: $color-light;
      color: $color;
    }
    .Collapsible__trigger.is-open {
      border-color: $color;
      background-color: $color;
      color: $color-white;
    }
  }

  .deliverables-summary {

    .training {
      @include changeCollapsibleColor($color-training, $color-training-light);

      .implementationEditContainer {
        background-color: $color-training-light;
      }

      .tabs-title {
        > a:hover {
          background-color: $color-training-light;
        }

        &.is-active {
          background-color: $color-training-light;
        }
      }
    }

    .product {
      @include changeCollapsibleColor($color-product, $color-product-light);

      .implementationEditContainer {
        background-color: $color-product-light;
      }

      .tabs-title {
        > a:hover {
          background-color: $color-product-light;
        }

        &.is-active {
          background-color: $color-product-light;
        }
      }
    }

    .serviceDelivery {
      @include changeCollapsibleColor($color-serviceDelivery, $color-serviceDelivery-light);

      .implementationEditContainer {
        background-color: $color-serviceDelivery-light;
      }

      .tabs-title {
        > a:hover {
          background-color: $color-serviceDelivery-light;
        }

        &.is-active {
          background-color: $color-serviceDelivery-light;
        }
      }
    }

    .Collapsible__trigger.is-open + .Collapsible__contentOuter {
      border-color: $color-border;
    }

    .Collapsible__contentInner {
      margin: 0;

      &:before, &:after {
        margin: 0;
      }

      .table-form {
        margin: 0;
        border: 0;

        button.implementation--date {
          padding: 0 !important;
          margin: 0 !important;
          min-height: 0;
          min-width: 0;
        }

        .implementationEditContainer {
          .implementationEdit {
            &Title {
              margin-top: 0;

              > div {
                margin-top: 0;
                margin-bottom: $margin-element;
                font-size: 1.1em;
              }

              &--addedNew {
                color: $color-add-item-dark;
              }

              &--editCurrent {
                color: $system-color-foreground;
              }
            }

            legend {
              margin-top: 0 !important;
            }

            .twoColumnRow > .columns {
              padding-left: $padding-element-x;
              padding-right: $padding-element-x;

              &:first-child {
                padding-left: 0;
              }

              &:last-child {
                padding-right: 0;
              }
            }

            .implementationEditButtonContainer {
              margin-top: $margin-element * 2;
            }
          }

          .districtTotalParticipants {
            legend {
              padding-bottom: $padding-element-y;
            }

            .columns {
              padding-right: $padding-element-x / 2;
              padding-bottom: $padding-element-x / 2;
              align-self: flex-end;
            }
          }
        }
      }

      .sourceDocumentation {
        &__upload {
          margin-bottom: $margin-element;
          display: inline-block;
        }
      }
    }

    .tileContainer {
      float: right;

      div.row {
        margin: 0;

        div.columns {
          width: 100%;
          max-width: 100%;
          white-space: nowrap;

          .tileContainer__tile {
            min-height: auto;

            .tileContainer__tile__content {
              font-size: 1.15rem;
            }
          }
        }
      }
    }
  }

  .sourceDocumentationFiles, .roiDocumentationFiles {
    &__fileDownload {
      margin-bottom: $margin-element;

      .button--download {
        width: 72%;
      }

      &--disabled {
        margin-bottom: $margin-element;
        .button--download {
          width: 90%;
        }
      }
    }
  }

  .project-information {
    .info-section {
      padding-bottom: $padding-element-y;

      h3 {
        margin-bottom: 0;
      }

      .row {
        padding-bottom: $padding-element-y * 0.5;
      }

      .columns {
        padding-left: $padding-element-x;
      }

      .budget-total {
        border-top: 1px solid $system-color-border;
        padding-top: $padding-element-y * 0.5;
      }
    }

    .programUser__phoneNumber {
      .columns {
        align-self: flex-end;
      }

      .input-theme {
        padding-bottom: 0;

        .columns {
          padding-left: 0;
        }
      }
    }

    .project__dates {
      .columns:last-child {
        align-self: flex-end;
      }
    }
  }

  .report-summary {
    .resetAuditContainer {
      margin-bottom: $margin-element;
      text-align: center;
    }

    > .tabs-content > .tabs-panel > .row > .Collapsible {
      .Collapsible__trigger {
        background-color: $color-white;
        color: $color-black;
        border: none;
        font-weight: bold;
        padding-left: 0;
      }

      .Collapsible__contentOuter {
        border: none;

        .Collapsible__contentInner {
          margin-left: 0;
        }
      }
    }
  }
}

.theme-print {
  .implementations-container {
    margin-left: $margin-element * 2;
  }

  .sourceDocumentation-container {
    .button {
      margin: 0;
      padding: 0;
      min-height: 0;

      span {
        margin: 0;
        padding: 0;
      }
    }
  }
}