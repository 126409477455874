%column-radio-field {
  display: block;
  padding: 1rem;
  border: 1px solid vary-get('color-border');
  margin-top: $margin-element;
  margin-bottom: $margin-element * 0.8;
}

.input-theme.input-theme__radio--column {
  .columns {
    text-align: center;
  }

  input[type="radio"] {
    &:focus + label {
      outline: none !important;
      &:after {
        background-color: $color-gray;
      }
    }

    + label {
      margin-left: $margin-element * 0.5;
      margin-right: $margin-element * 0.5;
      text-indent: 0;

      &:after {
        @extend %icon-radio-unchecked;
        @extend %column-radio-field;
      }

      &:before {
        display: none;
      }
    }

    &:checked + label {
      &:after {
        @extend %icon-radio-checked;
        @extend %column-radio-field;
      }

      &:before {
        display: none;
      }
    }
  }
}

