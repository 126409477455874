/*
	It is probably self-evident but a theme defines colours and images that give your application or site its look and feel. Separating the theme out into its own set of styles allows for those styles to be easily redefined for alternate themes.

	Themes can affect any of the primary types. It could override base styles like default link colours. It could change module elements such as chrome colours and borders. It could affect layout with different arrangements. It could also alter how states look.
*/
@import '../settings/config';
@import '../utilities/_vary';
@import '../settings/_settings.scss';

html,
body {
	background-color: $color-white;
}

body {
	color: $color-black;
}

.content {
	@media print {
		page-break-inside: unset;
	}

	> header {
		color: $system-color-header;
	}
}

@for $index from 1 through 6 {
	h#{$index}, .h#{$index} {
		font-size: 1.1rem;
		font-weight: bold;
		margin: $margin-element 0;
	}
}

h1, .h1 {
	font-size: 1.3rem;
}

h2, .h2 {
	font-size: 1.2rem;
}

h3, .h3 {
	font-size: 1.1rem;
}

div {
	position: relative;
}

legend,
label {
	font-weight: 600;

	.no-break {
		white-space: nowrap;
	}
}


.center {
	text-align: center !important;
}

.bold {
	font-weight: bold;
}


.divider {
	border-top: 1px solid $color-gray-light;
	margin-top: $margin-element;
	padding-right: $padding-element-y;
	padding-top: $padding-element-y;
}

.margin-bottom {
	margin-bottom: $margin-element;
}

.padding-right {
	padding-right: $padding-element-x !important;
}

.missing-data-highlight, .highlight-example {
	background-color: $color-highlight-dark !important;
	padding: $padding-element-x * 0.2 !important;
}

.page-directions-highlight {
	background-color: $color-highlight-dark !important;
	padding: $padding-element-x * 1.2 !important;
}

.text-left {
	text-align: left;
}
.text-right {
	text-align: right;
}

.no-wrap {
	white-space: nowrap;
}

.search-results-warning-container {
	text-align: center;

	.search-results-warning {
		background-color: $color-highlight-dark;
		display: inline-block;
		padding: $padding-element;
	}
}

.tool-tip {
	cursor: help;

	&--icon {
		cursor: unset;
		&::after {
			padding-left: .25em;
			@extend %icon-info;
		}
	}
}
