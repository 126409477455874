@import '../settings/_config';
@import '../utilities/_mixins';
@import '../utilities/_icons';
@import '../utilities/_placeholders';
@import '../utilities/_vary';

.Collapsible {
    background-color: white;
    margin-bottom: $margin-element;
    width: 100%;

    &:not(:first-child) {
        margin-top: $margin-element;
    }

    .Collapsible__contentInner {
        margin: 0 1em;

        @include firstAndLastElementMargins;

        > ul,ol {
            padding-left: $padding-element-x;
        }
    }

    .Collapsible__trigger.is-open + .Collapsible__contentOuter {
        @include vary($create: parent) {
            border-color: vary-get('color-background');
        }
    }

    .Collapsible__trigger {
        padding: $padding-element-small;
        padding-right: 3em;

        @include vary($create: parent) {
            border: 1px solid vary-get('color-border');
            color: vary-get('color-foreground');
        }

        &:not(.is-disabled):before {
            right: 1.25em;
            @include position(none, center);
            @extend %icon-enlarge;

            @media print {
                display: none !important;
            }
        }

        &.is-open {
            @include vary($create: parent) {
                background-color: vary-get('color-background');
                color: $color-white;
            }

            &:not(.is-disabled):before {
                @extend %icon-shrink;
            }
        }

        &:not(.is-open) {
            .Collapsible__trigger--notice {
                color: $color-alert;
            }
        }

        h1 {
            margin: 0;
        }
    }
}
