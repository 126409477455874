﻿@import '../settings/_config';
@import '../utilities/_mixins.scss';

nav.l-flex-container {
	justify-content: space-between;
	margin-top: 2em;
}

.menu {
	position: relative;
	max-width: $width-page;
	margin: auto;

	&:not(.is-active) {
		.menu__container {
			max-height: 0;
			overflow: hidden;
			visibility: hidden;
		}
	}

	.menu__container {
		background-color: $color-white;
		display: block;
		position: absolute !important;
		top: -5px;
		transition: all 0.2s ease;
		width: 100%;
		z-index: 1000 !important;

		@include roundedCorners;
		@include stickyShadow;

		@media screen and (min-width: $width-menu + ($gutter-page * 2)) {
			left: $gutter-page;
			max-width: $width-menu;
		}

		@media screen and (max-width: 300px) {
		}

		ul,
		ul li {
			list-style: none;
			margin: 0;
			padding: 0;
		}

		ul {
			li {
				min-height: 0.05em;
				vertical-align: middle;

				&:not(:last-child) {
					border-bottom: 1px solid $system-color-hover;
				}

				&:after {
					display: none;
					margin: 0;
				}

				a,
				.Menu_Collapsible__trigger {
					background-color: $color-white;
					color: $system-color-foreground;
					display: block;
					font-size: 1.1em;
					padding: 1em 1.3em;
					text-decoration: none;

					@include clickable($system-color-shadow);
				}

				.Menu_Collapsible {
					position: static;
					div {
						position: static;
					}

					ul {
						padding-left: $padding-element-y * 2;

						li {
							&:first-child {
								border-top: 1px solid $system-color-hover;
							}
						}
					}
				}
			}
		}
	}
}