﻿@import '../utilities/_vary';
@import '../settings/_config.scss';

.theme-print {
    .loader {
        display: none;
    }

    section {
        margin: 2rem auto;
    }

    .section--header {
        text-align: center;
        font-weight: $global-weight-bold;
        border: 1px solid $color-black;
        background-color: $color-gray-light;
        margin-top: $margin-element;
    }

    button {
        color: black !important;
        border-color: black !important;
    }

    .row {
        margin: 1rem auto;
        max-width: 100% !important;
    }

    .spp-cover-page {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
        text-align: center;
    }

    .report-header {
        display: flex;
        flex-direction: column;
        text-align: center;

        h1, h2 {
            margin: 0.25 * $margin-element 0;
        }
    }

    .completed-row td,
    .final-order-row th {
        border-top: 1px solid $color-black;
    }

    .report-not-found {
        text-align: center;
        font-weight: bold;
        font-style: italic;
    }

    .is-dangerouslySetHtml {
        margin: 1rem 0;
    }

    .task-icon-row {
        img {
            margin-right: 0.5rem;
        }
    }

    .table-print thead.no-head-group {
        display: table-row-group !important;
    }
}