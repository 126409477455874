@import '../utilities/_functions.scss';

//Groups of classes
$hover-exclude: '.is-disabled,.is-notClickable,.is-active,:disabled,[readonly]'; // need disable and readonly for buttons added back
$roundCorner-exclude: '.menu__container a,tr:first-child > *,tr:last-child > *';

//Standard Colors
$color-white: white(1);
$color-black: black(1);
$color-gray: black(.54, $color-white);
$color-gray-medium: black(.3, $color-white);
$color-gray-light: black(.1, $color-white);

$color-edit-item-dark: havelock(1, $color-white);
$color-edit-item-light: havelock(.05);
$color-edit-row: havelock(.1, $color-white);

$color-add-item-dark: atlantis(1, $color-white);
$color-add-item-light: atlantis(.125);
$color-add-row: atlantis(.1, $color-white);

$color-alert: cardinal(.9);
$color-delete: $color-alert;
$color-shadow: black(.4);
$color-link: rgba(0, 0, 238, .99);

$color-disabled-light: black(.06, $color-white);
$color-disabled-dark: black(.6, $color-white);

$color-highlight: gold(.1, $color-white);
$color-highlight-medium: gold(.3, $color-white);
$color-highlight-dark: gold(.6, $color-white);

$color-select-list: #f9f9fb;
$color-select-list-focus: #e0e0e6;

/*Border*/
$border-radius: 3px;

/*Width*/
$width-page: 1200px;
$width-page-login: 720px;
$width-message: 480px;
$width-menu: 300px;

/*Gutter*/
$gutter-page: 16px;

/*Margin*/
$margin-element: 1rem;

/*Opacity*/
$opacity-disabled: 1;

/*Padding*/
$padding-tile: rem-calc(10px);
$padding-icon-spacing: .25rem;

$padding-button: .5rem;
$padding-button: $padding-button;

$padding-element-y: 1rem;
$padding-element-x: 1rem;
$padding-element: $padding-element-y $padding-element-x;

$padding-element-x-small: .5rem;
$padding-element-x-xsmall: .25rem;

$padding-element-small-top: .25rem;
$padding-element-small: $padding-element-small-top $padding-element-x-small;

$padding-element-side: .1px $padding-element-x;
$padding-element-side-left: .1px .1px .1px $padding-element-x;
$padding-element-side-right: .1px $padding-element-x .1px .1px;


/*Context Theme*/
$system-color-background: defaultColor(.99);
$system-color-border: defaultColor(.50);
$system-color-foreground: defaultColor(.99);
$system-color-header: purple(.7, $color-black);
$system-color-hover: defaultColor(.15);
$system-color-hover-dark: defaultColor(.5);
$system-color-progress: defaultColor(.2);
$system-color-row: $color-disabled-light;
$system-color-rowhead: defaultColor(.3);
$system-color-rowsubhead: defaultColor(.1);
$system-color-rowfooter: defaultColor(.1);
$system-color-shadow: defaultColor(.4);