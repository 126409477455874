@import '../settings/config';
@import '../utilities/_vary.scss';
@import '../utilities/_mixins.scss';
@import '../utilities/_placeholders.scss';
@import 'taskTheme';

.theme-compliance {
    .mcNavigation {
        @extend .navigation-buttons
    }

    .mc-summary-due-now-table, .mc-summary-upcoming-table {
        @extend .summary-tables
    }
}
.compliance {
    &__filters {
        border: 1px solid $light-gray;
        padding: 1em;
    }

    &__requiredProtocol {
        padding: $padding-element;

        @include fancySection;

        &__indicator,
        &__classification {
            background-color: $color-white;
            padding: $padding-element-side;

            @include firstAndLastElementMargins;

            @include vary($create: parent) {
                border: 1px solid vary-get('color-border');
            }

            &--editing {
                @include hoverColor;

                > .row > .columns {
                    margin-top: $margin-element;

                    &:first-child {
                        @include breakpoint(xxlarge only) {
                            padding-right: $padding-element-x;
                        }
                    }
                }
            }
        }
    }

    &__studentRecord,
    &__validationNotes {
        background-color: $color-white !important;
        padding: $padding-element;

        @include fancySection;
    }

    &__indicatorProtocol,
    &__indicatorProtocolForm,
    &__matrixOfService,
    &__caseNote,
    &__reassessStandards {
        ol, ul {
            margin: 0;

            li {
                line-height: normal;

                &:after {
                    content: '';
                    margin-top: 0;
                }
            }
        }
    }

    &__correctiveActionPlan,
    &__indicatorProtocol,
    &__indicatorProtocolForm,
    &__matrixOfService,
    &__caseNote,
    &__reassessStandards {
        td ol {
            padding-left: $padding-element-x;
        }

        td.response-no-wrap {
            white-space: nowrap;
        }

        span.clarifying-text {
            font-size: 0.8em;
            font-style: italic;
        }

        .tfoot td {
            font-weight: bold;
        }
    }

    &__correctiveActionPlan {
        .activities-table,
        .associated-protocols {
            li {
                line-height: normal;

                &:after {
                    content: '';
                    margin-top: 0;
                }
            }
        }

        .activities-table {
            ul {
                padding-left: $padding-element-x;
                margin: 0;
            }

            .htmlDisplay--editable {
                margin-top: 0;
            }
        }

        .is-disabled {
            opacity: 1;
        }

        .activities-results-review-form {
            .Collapsible {
                margin: 0;
            }

            .results-details {
                margin-top: $margin-element;
            }
        }
    }

    &__indicatorProtocol,
    &__indicatorProtocolForm,
    &__matrixOfService {
        tbody th {
            @include vary($create: parent) {
                border-bottom: 1px solid vary-get('color-border');
                text-align: center;
            }
        }
    }

    &__indicatorProtocolForm {
        @include vary($create: parent) {
            border: 1px solid vary-get('color-border');
        }
        margin: $margin-element * 2 0;
        padding: $padding-element-y * .5 $padding-element-x;

        .responseContainer, .responseContainer.missing-data-highlight {
            padding: $padding-element !important;
        }

        .subQuestion__responseContainer {
            padding: $padding-element-y 0;

            .missing-data-highlight {
                padding: $padding-element-y * .8 $padding-element-x * .8 !important;
            }
        }
    }

    &__reassessStandards {
        .highlight-discrepancy {
            background-color: $color-highlight-medium;
        }

        h2:not(:first-child) {
            margin-top: $margin-element * 3;
        }

        tbody,
        thead {
            tr:first-child th {
                @include vary($create: parent) {
                    background-color: vary-get('color-rowhead');
                    border-bottom: none;
                    border-top: 1px solid vary-get('color-border');
                }
            }

            tr th {
                @include vary($create: parent) {
                    background-color: vary-get('color-row');
                    border-bottom: 1px solid vary-get('color-border');
                    border-top: none;
                }
            }

            th:last-child, td:last-child {
                border: 2px solid $black;
                border-top: none;
                border-bottom: none;
            }

            tr:first-child th:last-child {
                border-top: 2px solid $black;
            }

            tr:last-child td:last-child {
                border-bottom: 2px solid $black !important;
            }
        }

        .matrix-container {
            padding: $padding-element-y 0;
        }
    }

    &__district-list {
        th.district-noncompliance,
        td.district-noncompliance {
            @include vary($create: parent) {
                background-color: scale-color(vary-get('color-rowhead'), $lightness: 50%);
            }
        }

        th.district-noncompliance {
            @include vary($create: parent) {
                border-bottom: 1px solid vary-get('color-border');
            }
        }
    }

    &__activity {
        margin-top: $margin-element;
    }

    &__activity,
    &__review {
        .textContainer {
            margin-bottom: $margin-element;
        }
    }

    .indicator-text {
        padding-right: $padding-element-y * 2.2;
    }

    &__correctiveActionPlan,
    &__studentCorrectionResults {
        .correction-info {
            margin-bottom: $margin-element;
        }

        .highlight-needsRevision {
            padding: $padding-element;
            background-color: $color-highlight-medium;

            .acknowledge-container {
                margin-top: $margin-element * 2;
                padding: $padding-element-y $padding-element-x $padding-element-y $padding-element-x * 1.7;
                border: 1px solid $color-gray;
            }
        }
    }

    &__studentCorrectionResultsSummary {
        .table-grid {
            tr {
                vertical-align: top;

                ul, li:after {
                    margin: 0;
                }
            }
        }
    }

    &__submission-review-table {
        tbody tr {
            vertical-align: top;

            td {
                li:after {
                    content: '';
                    display: inline;
                    height: auto;
                    width: auto;
                    margin-top: auto;
                }
            }

            td:last-child {
                text-align: center;
            }
        }
    }
}

.compliance-print-report {
    ul {
        @extend .no-bullet;
        @extend .single-space;
    }

    th, tfoot td {
        vertical-align: bottom;
    }
    th.response-no-wrap, td.response-no-wrap
    {
        white-space: nowrap;
    }

    .row {
        max-width: 100%;
    }

    .report-summary {
        margin-top: $margin-element;
        margin-bottom: $margin-element * 2;
    }

    div.is-dangerouslySetHtml {
        margin: 0;
    }

    .no-report {
        text-align: center;
        font-weight: bold;
        font-style: italic;
        margin: $margin-element * 2;
    }
}
