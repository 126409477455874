﻿//placeholder for refactor
//need base module code from tablesTheme

table {
  tr,
  th,
  td {
    &.text-center {
      text-align: center;

      > td,
      > th {
        text-align: center;
      }
    }

    &.small-columns {
      td, th {
        width: 10%;
      }
    }
    &.medium-column {
      width: 20%;
    }
    &.large-column {
      width: 50%;
    }

    &.text-right {
      text-align: right;

      > td,
      > th {
        text-align: right;
      }
    }
  }
}