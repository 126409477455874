@import 'settings/config';
@import 'utilities/_vary.scss';
@import 'utilities/_mixins.scss';
@import 'utilities/_placeholders.scss';

//A state is something that augments and overrides all other styles. For example, an accordion section may be in a collapsed or expanded state. A message may be in a success or error state.

//States are generally applied to the same element as a layout rule or applied to the same element as a base module class.

.is-clickable{
    @include clickable;
}

.color {
    &--alert {
        color: $color-alert;
    }
}

.is-disabled {
    @extend %disabled;
}
.is-notClickable {
    @extend %notClickable;
}

.is-active {
    //for intellisense
}