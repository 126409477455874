#app .react-select {
  &__control {
    border-color: $color-gray;
    border-radius: $border-radius;
    padding: 0;
  }

  &__value-container {
    padding: 0 10px;
    margin: 0;
  }

  &__indicator {
    padding: 0;
    margin: 0;
    color: $color-black;

    svg {
      width: 14px;
    }
  }

  &__indicator-separator {
    margin-top: 4px;
    margin-bottom: 4px;
  }

  &__menu, &__menu-list {
    border-radius: 0;
    margin: 0;
    padding: 0;
    z-index: 1000;
  }

  &__menu {
    min-width: 100%;
    width: auto;
    white-space: nowrap;
  }


  &__placeholder {
    color: $color-black;
  }

  &__option {
    color: $color-black;
    padding: 5px 8px;
    background-color: $color-select-list;

    &--is-focused {
      background-color: $color-select-list-focus;
    }
  }
  &__single-value,
  &__input {

    input {
      min-height: rem-calc(33px);
    }

  }
}