﻿%icomoon {
    display: inline-block;
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    line-height: 1; //needed sometimes, apply when needed
    speak: none;
    text-transform: none;
    vertical-align: baseline; //puts icon in line with text
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

%icon-enlarge {
    content: "\e98b";
    @extend %icomoon;
}

%icon-shrink {
    content: "\e98c";
    @extend %icomoon;
}

%icon-attachment {
    content: "\e9cd";
    @extend %icomoon;
}

%icon-checkbox-checked {
    content: "\ea52";
    @extend %icomoon;
}

%icon-checkbox-unchecked {
    content: "\ea53";
    @extend %icomoon;
}

%icon-radio-checked {
    content: "\ea54";
    @extend %icomoon;
}

%icon-radio-unchecked {
    content: "\ea56";
    @extend %icomoon;
}

%icon-back {
    content: "\ea1c";
    transform: rotate(180deg);
    @extend %icomoon;
}

%icon-forward {
    content: "\ea1c";
    @extend %icomoon;
}

%icon-upload {
    content: "\e961";
    @extend %icomoon;
}

%icon-download {
    content: "\e960";
    @extend %icomoon;
}

%icon-delete {
    content: "\e9ac";
    padding-left: 1px; //center font
    @extend %icomoon;
}

%icon-add,
%icon-plus {
    content: "\ea0a";
    @extend %icomoon;
}

%icon-remove,
%icon-minus {
    content: "\ea0b";
    @extend %icomoon;
}

%icon-first {
    content: "\ea21";
    @extend %icomoon;
}

%icon-previous {
    content: "\ea23";
    @extend %icomoon;
}

%icon-next {
    content: "\ea24";
    @extend %icomoon;
}

%icon-last {
    content: "\ea22";
    @extend %icomoon;
}

%icon-reset {
    content: "\e94d";
    @extend %icomoon;
}

%icon-configure {
    content: "\e994";
    @extend %icomoon;
}

%icon-edit {
    content: "\e905";
    @extend %icomoon;
}

%icon-order {
    content: "\ea7f";
    transform: rotate(90deg);
    @extend %icomoon;
}

%icon-save {
    content: "\ea10";
    @extend %icomoon;
}

%icon-exit,
%icon-cancel {
    content: "\ea0f";
    @extend %icomoon;
}

%icon-clone {
    content: "\e92c";
    @extend %icomoon;
}

%icon-cloneSet {
    content: "\e900";
    @extend %icomoon;
}

%icon-needsReview, %icon-view {
    content: "\e9ce";
    @extend %icomoon;
}

%icon-notification,
%icon-needsRevision {
    content: "\ea08";
    @extend %icomoon;
}

%icon-reviewed {
    content: "\ea10";
    @extend %icomoon;
}

%icon-history {
    content: "\e94d";
    @extend %icomoon;
}

%icon-restrict {
    content: "\ea0e";
    @extend %icomoon;
}

%icon-search {
    content: "\e986";
    font-size: .95em;
    @extend %icomoon;
}

%icon-print {
    content: "\e954";
    @extend %icomoon;
}

%icon-moveUp,
%icon-insertBefore
{
    content: "\ea47";
    @extend %icomoon;
}

%icon-moveDown,
%icon-insertAfter
{
    content: "\ea48";
    @extend %icomoon;
}

%icon-impersonate {
    content: "\e972";
    @extend %icomoon;
}

%icon-replace {
    content: "\e972";
    @extend %icomoon;
}

%icon-content {
    content: "\e922";
    @extend %icomoon;
}

%icon-info {
    content: "\ea0c";
    @extend %icomoon;
}

// stock icomoon start
/*


.icon-loop:before {
  content: "\e900";
}
.icon-refresh:before {
  content: "\e900";
}
.icon-reload:before {
  content: "\e900";
}
.icon-reset:before {
  content: "\e900";
}
.icon-arrows:before {
  content: "\e900";
}
.icon-reload-alt:before {
  content: "\e901";
}
.icon-refresh2:before {
  content: "\e901";
}
.icon-reset2:before {
  content: "\e901";
}
.icon-arrow:before {
  content: "\e901";
}
.icon-pencil:before {
  content: "\e905";
}
.icon-blog:before {
  content: "\e909";
}
.icon-copy:before {
  content: "\e92c";
}
.icon-folder-plus:before {
  content: "\e931";
}
.icon-folder-minus:before {
  content: "\e932";
}
.icon-history:before {
  content: "\e94d";
}
.icon-upload:before {
  content: "\e961";
}
.icon-enlarge:before {
  content: "\e98b";
}
.icon-shrink:before {
  content: "\e98c";
}
.icon-bin:before {
  content: "\e9ac";
}
.icon-menu2:before {
  content: "\e9be";
}
.icon-cloud-upload:before {
  content: "\e9c3";
}
.icon-upload2:before {
  content: "\e9c6";
}
.icon-link:before {
  content: "\e9cb";
}
.icon-attachment:before {
  content: "\e9cd";
}
.icon-plus:before {
  content: "\ea0a";
}
.icon-play:before {
  content: "\ea1c";
}
.icon-first:before {
  content: "\ea21";
}
.icon-last:before {
  content: "\ea22";
}
.icon-previous:before {
  content: "\ea23";
}
.icon-next:before {
  content: "\ea24";
}
.icon-move-up:before {
  content: "\ea46";
}
.icon-checkbox-checked:before {
  content: "\ea52";
}
.icon-checkbox-unchecked:before {
  content: "\ea53";
}
.icon-mail2:before {
  content: "\ea84";
}
.icon-mail3:before {
  content: "\ea85";
}
.icon-download:before {
  content: "\e960";
}
.icon-cloud-download:before {
  content: "\e9c2";
}
.icon-download2:before {
  content: "\e9c5";
}
.icon-eye:before {
  content: "\e9ce";
}
.icon-warning:before {
  content: "\ea07";
}
.icon-notification:before {
  content: "\ea08";
}
.icon-minus:before {
  content: "\ea0b";
}
.icon-cancel-circle:before {
  content: "\ea0d";
}
.icon-blocked:before {
  content: "\ea0e";
}
.icon-cross:before {
  content: "\ea0f";
}
.icon-checkmark:before {
  content: "\ea10";
}
.icon-radio-checked:before {
  content: "\ea54";
}
.icon-radio-unchecked:before {
  content: "\ea56";
}
.icon-section:before {
  content: "\ea76";
}
.icon-printer:before {
  content: "\e954";
}
.icon-floppy-disk:before {
  content: "\e962";
}
.icon-bubble:before {
  content: "\e96b";
}
.icon-bubble2:before {
  content: "\e96e";
}
.icon-users:before {
  content: "\e972";
}
.icon-user-plus:before {
  content: "\e973";
}
.icon-user-minus:before {
  content: "\e974";
}
.icon-search:before {
  content: "\e986";
}
.icon-key:before {
  content: "\e98d";
}
.icon-lock:before {
  content: "\e98f";
}
.icon-unlocked:before {
  content: "\e990";
}
.icon-cog:before {
  content: "\e994";
}


*/
//stock icomoon end

