﻿.dropdown {
    display: inline-block;
    outline: none;
    position: relative;

    &:hover .dropdown__content,
    &:focus .dropdown__content,
    &:active .dropdown__content {
        max-height: none;
        opacity: 1;
        overflow: visible;
    }

    .dropdown__content {
        max-height: 0;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        right: 0;
        //border: none;
    }
}
