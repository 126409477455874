﻿#toast-container {
    .toast {
        background-position-y: 1rem;
        padding: 1rem 1rem 1rem 3rem;

        .toast-title:not(:empty) {
            margin-bottom: 1rem;
        }

        .toast-message:not(:empty) {
            ul {
                margin: 0;
                padding: 0;
            }
        }
    }
}
