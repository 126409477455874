﻿@import '../settings/_config.scss';
@import '../utilities/_mixins.scss';

.switch {
    // zero out foundations defaults
    height: auto;
    margin-bottom: 0;
    font-size: 1rem;
    color: black;
    //
    border: 5px solid $color-gray-light;
    border-radius: $border-radius;
    padding: $padding-element-small-top;
    padding-bottom: .5rem;


    label {
        border-radius: $border-radius;
        margin: auto;

        &.is-disabled {
            cursor: not-allowed;
        }

        &:after {
            border-radius: $border-radius;
        }

        span {
            @include visuallyhidden;
        }
    }

    legend {
        margin: auto;
    }
}
