
.notification {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(50%, -50%);
    z-index: 100;

    .button--notification {
        cursor: help !important;
        position: relative;
        z-index: 101;

        &:focus + .notification__message,
        &:active + .notification__message,
        &:hover + .notification__message {
            //show
            border-width: 1px;
            max-height: 5em;
            opacity: 1;
            overflow: visible;
            display: block;
        }

        &:before {
            background-color: white;
            border-radius: 50%;
            color: red;
            font-size: 32px;
            line-height: .99; //white outline if 1
        }
    }

    .notification__message {
        background-color: red;
        border-style: solid;
        color: white;
        position: absolute;
        right: 42px;
        top: 50%;
        transform: translate(0, -50%);
        transition: opacity .15s;
        white-space: nowrap;
        padding: .5rem 1rem;
        //hide
        border-width: 0;
        max-height: 0;
        opacity: 0;
        overflow: hidden;
        display: none;

        &:after {
            border-width: 5px;
            border-style: solid;
            border-color: transparent transparent transparent red;
            content: "";
            position: absolute;
            top: 50%;
            left: 100%;
            margin-top: -5px;
        }
    }
}