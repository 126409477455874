@import '../settings/_config';
@import '../utilities/_mixins';
@import '../utilities/_vary';
@import '../utilities/_placeholders.scss';


.SectionHeader {
    margin-bottom: 0;
    padding-left: 0.5rem;
    color: $color-white;
    @include vary($create: parent) {
        background-color: vary-get('color-background');
    }
    :first-child {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }
}
