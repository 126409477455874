.modal {
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.4);
}

.modal-content-container {
  background-color: #fff;
  margin: 2% auto;
  width: 100%;
  overflow-y: auto;
  max-height: 95vh;
  z-index: 1000;
  border: 1px solid $black;

  .modal-content {
    padding: $padding-element-x;
  }
}

html.modal-open {
    overflow: hidden;
}