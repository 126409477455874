@import '../utilities/vary';
@import '../settings/_config';
@import '../utilities/mixins';
@import '../utilities/placeholders';

.tileContainer {
    margin-bottom: $margin-element;

    &:not(:first-child) {
        margin-top: $margin-element;
    }

    .row {
        margin: -$padding-tile;
    }

    .columns {
        padding: $padding-tile;
    }

    .tileContainer__title {
        margin-bottom: $margin-element/2;
    }

    .tileContainer__tile {
        height: 100%;
        min-height: 90px;

        @include vary($create: parent) {
            border: 1px solid vary-get('color-border');
            color: vary-get('color-foreground');
        }

        &.tileContainer__tile--medium {
            min-height: 56px;
        }

        &.tileContainer__tile--large {
            min-height: 125px;
        }

        &.tileContainer__tile--new {
            background-color: $color-add-item-light;
            border-style: dashed;
            border-color: $color-add-item-dark;
            color: $color-add-item-dark;
            justify-content: center;

            @media print {
                display: none;
            }

            &.tileContainer__tile--medium {
                font-size: 1.5rem;
            }

            &.tileContainer__tile--large {
                font-size: 2rem;
            }

            .tileContainer__tile__content {
                &:before {
                    @extend %icon-plus;
                    @extend %buttonSmall;
                    border-width: 2px !important;
                    margin-right: 0.5rem;
                }
            }

            .tileContainer__tile__footer {
                background-color: $color-gray;
            }
        }

        .tileContainer__tile__content:only-child {
            display: flex;
            align-items: center;
            justify-content: center;
        }


        .tileContainer__tile__footer {
            color: white(.99);

            @media print {
                color: $color-black;

                @include vary($create: parent) {
                    border-top: 1px solid vary-get('color-border');
                }
            }

            @include vary($create: parent) {
                background-color: vary-get('color-background');
            }
        }

        > *:not(:empty) {
            padding: $padding-tile;
        }
    }

    button:disabled .tileContainer__tile {
        background-color: $color-disabled-light;
        border-color: $color-disabled-dark;
        color: $color-disabled-dark;
    }
}

tfoot {
    .tileContainer {
        margin: $margin-element;
    }
}
